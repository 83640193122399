import { BoxProps, Box } from "@chakra-ui/react";

import { AppColors } from "../../core/custom-theme";

interface Props extends BoxProps {
  containsTable?: boolean;
}

export function PanelBox(props: Props) {
  const { containsTable, ...rest } = props;
  const borderStyle = `1px solid ${AppColors.tableHeaderBorder}`;

  return (
    <Box
      backgroundColor="white"
      border={borderStyle}
      borderRadius="4px"
      padding={containsTable ? undefined : 4}
      {...rest}
    />
  );
}
