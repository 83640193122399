import { useLocation } from "react-router-dom";
import { Box, Image, Text, Stack } from "@chakra-ui/react";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";

import { MainNavLink } from "./main-nav-link";
import { Container } from "../container";
import AppLink from "../../app-link";
import { getMainNavDefinition } from "../main-nav-definition";
import { MainNavProfile } from "./main-nav-profile";
import { useMainMenuPartnerLogoQuery } from "../../../generated/graphql";
import AppIcon from "../../app-icon";
import { MAIN_NAV_HEIGHT } from "../../../constants/constants";
import { AppColors } from "../../../core/custom-theme";

interface NavItemProps {
  name: string;
  isActive?: boolean;
  to: string;
  onClick?: () => void;
}

function NavItem(props: NavItemProps) {
  const { name, to, isActive } = props;
  return (
    <Box>
      <MainNavLink to={to} style={{ color: isActive ? AppColors.white : AppColors.linkDarkGray }}>
        {name}
      </MainNavLink>
    </Box>
  );
}

interface Props {
  hasSecondaryNav?: boolean;
}

export function MainNavFull(props: Props) {
  const location = useLocation();
  const { hasSecondaryNav } = props;

  const { data } = useMainMenuPartnerLogoQuery({
    fetchPolicy: "cache-and-network",
  });
  const url = data?.customer?.partner?.reportingLogoUrl ?? null;
  let isReviewEnabled = false;
  for (const customerBusinessListingSpec of data?.customer?.customerBusinessListingSpecs.nodes ?? []) {
    if (customerBusinessListingSpec.isReviewEnabled) {
      isReviewEnabled = true;
    }
  }

  const navDefinition = getMainNavDefinition({
    analyticsVisible: !!data?.customer?.analyticsReportingInlineFrameUrl,
    billingVisible: true,
    reviewsVisible: isReviewEnabled,
  });

  return (
    <Box
      height={MAIN_NAV_HEIGHT}
      backgroundColor={AppColors.textNormal}
      display="grid"
      gridTemplateColumns="1fr minmax(300px, 1120px) 1fr"
      justifyContent="stretch"
      alignItems="center"
      position="sticky"
      top="0"
      zIndex={999}
      marginBottom={hasSecondaryNav === true ? 0 : 2}
      boxShadow={hasSecondaryNav === true ? undefined : "2px 0px 12px rgba(0, 0, 0, 0.6)"}
    >
      <Box gridColumn="1">
        <Box paddingLeft={4}>
          <AppLink to="/">
            {!url && (
              <Text marginLeft={2}>
                <AppIcon icon={faChartLine} standardRightMargin />
                {data?.customer?.partner.name ?? "Launch Pad"}
              </Text>
            )}
            {url && <Image objectFit="contain" src={url} maxHeight={50} maxWidth={200} crossOrigin="" />}
          </AppLink>
        </Box>
      </Box>
      <Container gridColumn="2" display="flex">
        <Stack isInline spacing={6} shouldWrapChildren>
          {navDefinition
            .map((navItem) => {
              if (!navItem.visible) {
                return null;
              }
              return (
                <NavItem
                  key={navItem.name}
                  name={navItem.name}
                  to={navItem.to}
                  isActive={location.pathname.startsWith(navItem.to)}
                />
              );
            })
            .filter((navItem) => !!navItem)}
        </Stack>
      </Container>
      <Box color={AppColors.white} gridColumn="3">
        <MainNavProfile />
      </Box>
    </Box>
  );
}
