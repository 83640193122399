import { useContext } from "react";
import { Box } from "@chakra-ui/react";

import { AuthContext } from "../../../core/auth-manager";
import { MainNavSmallLink } from "./main-nav-small-link";

export function MainNavSmallProfile() {
  const authContext = useContext(AuthContext);
  const { viewer, logout } = authContext;

  return (
    <Box>
      <Box marginBottom={4}>
        <span style={{ display: "block" }}>{viewer?.user?.fullName ?? "Not Logged In"}</span>
        <span style={{ display: "block", fontSize: ".75rem" }}>{authContext.customer?.name ?? ""}</span>
      </Box>
      <Box marginBottom={2}>
        <MainNavSmallLink to="/account" name="Account Setup" isActive={false} />
      </Box>
      <Box marginBottom={2}>
        <MainNavSmallLink onClick={logout} name="Logout" isActive={false} to="" />
      </Box>
    </Box>
  );
}
