export const BUSINESS_TYPE_OPTIONS = [
  {
    value: "Local",
    name: "Local",
  },
  {
    value: "National",
    name: "National",
  },
];
