import { DateTime } from "luxon";
import { Box, Flex, Text } from "@chakra-ui/react";

import { PageHeading } from "../../components/page-heading";
import { ConversationCreateDialog } from "./conversation-create-dialog";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { PrimaryButton } from "../../components/buttons";
import { Table, TableHead, TableRow, TableHeaderCell, TableBody, TableCell } from "../../components/table";
import { MMM_D_YYYY_H_MM_A } from "../../constants/constants";
import AppLink from "../../components/app-link";
import { ActionItemsBar } from "../../common/action-items-bar";
import { useMessages } from "./use-messages";

export function Messages() {
  const { loading, newMessage, isAdmin, onNewMessage, onClose, conversations } = useMessages();

  return (
    <LayoutBase pageTitle="Messages">
      <MainNav />
      <Container centerHorizontally>
        <ActionItemsBar />
        <PageHeading>Messages</PageHeading>
        <PanelGroup>
          <PanelTop>
            <Flex justifyContent="flex-end">
              <Box>
                <PrimaryButton isDisabled={isAdmin} size="sm" onClick={onNewMessage} variant="solid" colorScheme="blue">
                  New Message
                </PrimaryButton>
              </Box>
            </Flex>
          </PanelTop>
          <PanelBottom containsTable>
            <Table isLoading={loading} isEmpty={conversations.length === 0}>
              <TableHead>
                <TableRow key={1}>
                  <TableHeaderCell>Subject</TableHeaderCell>
                  <TableHeaderCell>Created</TableHeaderCell>
                  <TableHeaderCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {conversations.map((conversation) => (
                  <TableRow key={conversation.id}>
                    <TableCell>
                      <AppLink to={`/messages/${conversation.id}`}>
                        <Text isTruncated>{conversation.subject}</Text>
                      </AppLink>
                    </TableCell>
                    <TableCell>{DateTime.fromJSDate(conversation.createdAt).toFormat(MMM_D_YYYY_H_MM_A)}</TableCell>
                    <TableCell>
                      <span />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PanelBottom>
        </PanelGroup>
        <ConversationCreateDialog isOpen={newMessage} onClose={onClose} />
      </Container>
    </LayoutBase>
  );
}
