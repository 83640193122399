import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Box,
} from "@chakra-ui/react";

import { PanelBox } from "../../components/panels";

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

export function PackageHelpModal(props: Props) {
  const { onClose, isOpen } = props;
  return (
    <Modal onClose={onClose} isOpen={isOpen} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Package Help</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box paddingBottom={2}>
            <Box>
              <Stack spacing={2}>
                <Text>It comes down to competition and market size.</Text>
                <Text>
                  Example: If you are a lawyer in Las Vegas, you will have a lot more competition than a lawyer in
                  Billings, Montana.
                </Text>
                <Text>
                  Example2: Some businesses will be more competitive. Pest Control will be more competitive than an HVAC
                  contractor.
                </Text>
              </Stack>
            </Box>
            <Box>
              <Stack spacing={2}>
                <Text>Local Businesses</Text>
                <Stack isInline spacing={2}>
                  <PanelBox width="100%">
                    <Stack spacing={4}>
                      <Box>
                        <Text>Small Market</Text>
                        <Text>Low Competition -- $550</Text>
                      </Box>
                      <Box>
                        <Text>Small Market</Text>
                        <Text>Med Competition -- $1000</Text>
                      </Box>
                      <Box>
                        <Text>Small Market</Text>
                        <Text>High Competition -- $1500</Text>
                      </Box>
                    </Stack>
                  </PanelBox>
                  <PanelBox width="100%">
                    <Stack spacing={4}>
                      <Box>
                        <Text>Med Market</Text>
                        <Text>Low Competition -- $1000</Text>
                      </Box>
                      <Box>
                        <Text>Med Market</Text>
                        <Text>Med Competition --$1500</Text>
                      </Box>
                      <Box>
                        <Text>Med Market</Text>
                        <Text>High Competition --$2000</Text>
                      </Box>
                    </Stack>
                  </PanelBox>
                  <PanelBox width="100%">
                    <Stack spacing={4}>
                      <Box>
                        <Text>Large Market</Text>
                        <Text>Low Competition -- $1500</Text>
                      </Box>
                      <Box>
                        <Text>Large Market</Text>
                        <Text>Med Competition -- $2500</Text>
                      </Box>
                      <Box>
                        <Text>Large Market</Text>
                        <Text>High Competition -- $3500</Text>
                      </Box>
                    </Stack>
                  </PanelBox>
                </Stack>
              </Stack>
            </Box>
            <Box>
              <Stack spacing={2}>
                <Text>National and Ecommerce Businesses</Text>
                <Stack isInline spacing={2} justifyContent="space-between">
                  <PanelBox width="100%">
                    <Stack spacing={4}>
                      <Box>
                        <Text>Starter</Text>
                        <Text>Low Competition -- $1000</Text>
                      </Box>
                      <Box>
                        <Text>Recommended</Text>
                        <Text>Low Competition -- $1500</Text>
                      </Box>
                      <Box>
                        <Text>Aggressive</Text>
                        <Text>Low Competition -- $2500</Text>
                      </Box>
                    </Stack>
                  </PanelBox>
                  <PanelBox width="100%">
                    <Stack spacing={4}>
                      <Box>
                        <Text>Starter</Text>
                        <Text>Med Competition -- $1500</Text>
                      </Box>
                      <Box>
                        <Text>Recommended</Text>
                        <Text>Med Competition -- $2000</Text>
                      </Box>
                      <Box>
                        <Text>Aggressive</Text>
                        <Text>Med Competition -- $3000</Text>
                      </Box>
                    </Stack>
                  </PanelBox>
                  <PanelBox width="100%">
                    <Stack spacing={4}>
                      <Box>
                        <Text>Starter</Text>
                        <Text>High Competition -- $3000</Text>
                      </Box>
                      <Box>
                        <Text>Recommended</Text>
                        <Text>High Competition -- $4000</Text>
                      </Box>
                      <Box>
                        <Text>Aggressive</Text>
                        <Text>High Competition -- $5000</Text>
                      </Box>
                    </Stack>
                  </PanelBox>
                </Stack>
              </Stack>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
