export const CUSTOMER_CAMPAIGN_INVOICE_STATUS = {
  DRAFT: {
    id: "draft",
    name: "Draft",
    description: "The invoice has not yet been finalized and may still be changed before it can be paid.",
  },
  OPEN: {
    id: "open",
    name: "Open",
    description: "The invoice has been finalized and is ready for payment.",
  },
  COMPLETED: {
    id: "completed",
    name: "Completed",
    description: "The invoice has been paid.",
  },
};
