import React from "react";
import { Helmet } from "react-helmet";
import { Box } from "@chakra-ui/react";
import { Global } from "@emotion/react";
import { AppColors } from "../../core/custom-theme";

interface Props {
  children: React.ReactNode;
  pageTitle: string;
}

export function LayoutBase(props: Props) {
  const { children, pageTitle } = props;
  return (
    <Box as="main" marginBottom={12}>
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {children}
    </Box>
  );
}
