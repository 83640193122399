import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { FormikHelpers, useFormik } from "formik";
import * as yup from "yup";

import { useRequestPasswordResetMutation } from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";
import { APP_NAME } from "../../constants/constants";

const resetPasswordSchema = yup.object().shape({
  email: yup.string().label("Email").required().email().max(255),
});

interface FormValues {
  email: string;
}

export function useRequestPasswordReset() {
  const [requestPasswordReset] = useRequestPasswordResetMutation();
  const toast = useToast();
  const history = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function onSubmit(values: FormValues, _formikHelpers: FormikHelpers<FormValues>) {
    try {
      await requestPasswordReset({
        variables: {
          input: {
            email: values.email,
            origin: APP_NAME,
          },
        },
      });
      toast({
        title: "Password Reset Requested",
        description: "Please check your email for instructions on resetting your password.",
        status: "info",
      });
      history("/login");
    } catch (e: any) {
      toast({ title: "Reset Password", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      email: "",
    },
    validateOnBlur: true,
    validationSchema: resetPasswordSchema,
    onSubmit,
  });

  return {
    formik,
  };
}
