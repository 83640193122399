import { FormLabel, Stack, Select, FormControl, RadioGroup, Radio, Button, Heading, Flex } from "@chakra-ui/react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import { PageHeading } from "../../components/page-heading";
import { usePartnerPackages } from "./use-partner-packages";
import { Table, TableHead, TableRow, TableHeaderCell, TableBody, TableCell } from "../../components/table";
import { PartnerPackageStatusIndicator } from "../../common/partner-package-status-indicator";
import { currencyFormatter } from "../../util/formatter";
import { PanelGroup, PanelBottom, PanelTop, PanelMiddle } from "../../components/panels";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { PartnerPackages_PackageFragment } from "../../generated/graphql";
import { BUSINESS_TYPE_OPTIONS } from "../../constants/business-type-options";
import AppIcon from "../../components/app-icon";
import { PrimaryButton, PrimaryLinkButton } from "../../components/buttons";
import { Campaign } from "./campaign";
import { useCampaign } from "./use-campaign";
import { Loader } from "../../components/loader";
import { LOADER_TYPE } from "../../constants/constants";
import { ActionItemsBar } from "../../common/action-items-bar";
import { PackageHelpModal } from "./package-help-modal";

export function PartnerPackages() {
  const {
    loading,
    createCampaignLoading,
    packages,
    formik,
    onCustomerMarketSizeChange,
    onIsCallRequiredChange,
    selectedPackage,
    onSelectPackage,
    onSave,
  } = usePartnerPackages();

  const {
    loading: campaignLoading,
    customerCampaigns,
    showPackage,
    onPackageHelpClick,
    onPackageHelpClose,
  } = useCampaign();

  return (
    <LayoutBase pageTitle="Packages">
      <MainNav />
      {(loading || campaignLoading || createCampaignLoading) && <Loader type={LOADER_TYPE.fullView} />}
      {!campaignLoading && (
        <Container centerHorizontally>
          <ActionItemsBar />
          <Flex alignItems="center">
            <PageHeading>Packages</PageHeading>
          </Flex>
          {customerCampaigns.length === 0 && (
            <PanelGroup>
              <PanelTop showBottomBorder>
                <Flex alignItems="left" marginBottom={4}>
                  <PrimaryLinkButton onClick={onPackageHelpClick}>View Package Details</PrimaryLinkButton>
                </Flex>
                <Stack spacing={4}>
                  <FormControl isRequired isInvalid={!!formik.errors.customerMarketSize}>
                    <FormLabel>Business Type</FormLabel>
                    <Select
                      id="customerMarketSize"
                      value={formik.values.customerMarketSize}
                      onChange={onCustomerMarketSizeChange}
                      placeholder="Select One"
                      variant="outline"
                    >
                      {BUSINESS_TYPE_OPTIONS.map((option) => (
                        <option value={option.value} key={option.value}>
                          {option.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl isRequired isInvalid={!!formik.errors.isCallRequired}>
                    <FormLabel>Do you want a monthly call to review with an SEO Specialist?</FormLabel>
                    <RadioGroup
                      id="isCallRequired"
                      value={formik.values.isCallRequired ? "yes" : "no"}
                      onChange={onIsCallRequiredChange}
                    >
                      <Stack spacing={2} isInline>
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </PanelTop>
              <PanelMiddle containsTable>
                <Table isLoading={loading} isEmpty={packages.length === 0}>
                  <TableHead>
                    <TableRow key="head">
                      <TableHeaderCell>Name</TableHeaderCell>
                      <TableHeaderCell>Status</TableHeaderCell>
                      <TableHeaderCell>Monthly Spend</TableHeaderCell>
                      <TableHeaderCell>Months</TableHeaderCell>
                      <TableHeaderCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {packages.map((partnerPackage: PartnerPackages_PackageFragment) => {
                      const isSelected = selectedPackage?.id === partnerPackage.id;
                      return (
                        <TableRow key={partnerPackage.id}>
                          <TableCell>{partnerPackage.name}</TableCell>
                          <TableCell>
                            <PartnerPackageStatusIndicator isActive={partnerPackage.isActive} />
                          </TableCell>
                          <TableCell>{currencyFormatter.format(partnerPackage.monthlySpend)}</TableCell>
                          <TableCell>{partnerPackage.months}</TableCell>
                          <TableCell>
                            <Button
                              size="sm"
                              onClick={onSelectPackage.bind(null, partnerPackage)}
                              isDisabled={isSelected}
                              variant="link"
                              colorScheme="blue"
                            >
                              {isSelected && <AppIcon icon={faCheck} standardRightMargin />}
                              {isSelected ? "Selected" : "Select"}
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </PanelMiddle>
              <PanelBottom>
                <PrimaryButton onClick={onSave}>Save</PrimaryButton>
              </PanelBottom>
            </PanelGroup>
          )}
          <PanelGroup>
            <PanelTop>
              <Heading size="md">SEO Packages</Heading>
            </PanelTop>
            <PanelBottom containsTable>
              <Table isEmpty={customerCampaigns.length === 0} isLoading={loading}>
                <TableHead>
                  <TableRow key="head">
                    <TableHeaderCell>Name</TableHeaderCell>
                    <TableHeaderCell>Start Date</TableHeaderCell>
                    <TableHeaderCell>Duration</TableHeaderCell>
                    <TableHeaderCell>Monthly Spend</TableHeaderCell>
                    <TableHeaderCell>Status</TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customerCampaigns.map((campaign) => (
                    <Campaign key={campaign.id} campaign={campaign} />
                  ))}
                </TableBody>
              </Table>
            </PanelBottom>
          </PanelGroup>
        </Container>
      )}
      <PackageHelpModal onClose={onPackageHelpClose} isOpen={showPackage} />
    </LayoutBase>
  );
}
