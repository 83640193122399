import React from "react";
import { Link, Text } from "@chakra-ui/react";

import { useActionItemsBarQuery } from "../../generated/graphql";
import { AlertWarning } from "../../components/alerts";
import { AppColors } from "../../core/custom-theme";

export const ActionItemsBar = React.memo(function ActionItemsBar() {
  const { data, loading, error } = useActionItemsBarQuery({
    fetchPolicy: "network-only",
  });

  const tasks = data?.pendingTasks ?? [];

  if (loading || error || tasks.length === 0) {
    return null;
  }

  return (
    <>
      {tasks.map((task) => (
        <AlertWarning key={task.id}>
          <Text>
            You have a pending {task.taskType.name}.{" "}
            <Link color={AppColors.linkBlue} href={`/tasks/${task.id}`}>
              Review Now
            </Link>
          </Text>
        </AlertWarning>
      ))}
    </>
  );
});
