import { Box, Text } from "@chakra-ui/react";
import { faSync } from "@fortawesome/free-solid-svg-icons";

import AppIcon from "../../components/app-icon";

export function Loading() {
  return (
    <Box height="100vh" width="100%" justifyContent="center" alignItems="center" display="flex">
      <Box width="100%" maxWidth={300} textAlign="center">
        <Text marginBottom={8}>
          <AppIcon size="8x" icon={faSync} spin />
        </Text>
        <Text fontSize="xl">Logging in...</Text>
      </Box>
    </Box>
  );
}
