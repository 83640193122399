import { Box, Text, useTheme } from "@chakra-ui/react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import AppIcon from "../../components/app-icon";

export function SsoError() {
  const theme = useTheme();
  return (
    <Box height="100vh" width="100%" justifyContent="center" alignItems="center" display="flex">
      <Box width="100%" maxWidth={500} textAlign="center">
        <Text marginBottom={8} color={theme.colors.red[500]}>
          <AppIcon size="8x" icon={faExclamationTriangle} />
        </Text>
        <Text fontSize="xl" color={theme.colors.red[500]}>
          There was a problem with the URL you provided.
        </Text>
      </Box>
    </Box>
  );
}
