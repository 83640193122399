import { useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { useClipboard } from "use-clipboard-copy";

import { useOrderContentItemDeliveryQuery } from "../../generated/graphql";
import { AuthContext } from "../../core/auth-manager";

export function useDeliverable() {
  const { publicId } = useParams<"publicId">();
  const toast = useToast();
  const clipboard = useClipboard();
  const authContext = useContext(AuthContext);
  const history = useNavigate();

  const queryResult = useOrderContentItemDeliveryQuery({
    fetchPolicy: "network-only",
    variables: {
      publicId: publicId ?? "",
    },
  });

  const delivery = queryResult.data?.orderContentItem?.contentItemDelivery ?? null;

  if (!delivery && !queryResult.loading) {
    toast({ title: "Invalid URL", description: "Please double check your URL and try again.", status: "error" });
    history("/");
  }

  function copyToClipboard(textToCopy: string) {
    clipboard.copy(textToCopy);
    toast({ title: "Copied", status: "info" });
  }

  return {
    loading: queryResult.loading || authContext.isLoading,
    delivery,
    copyToClipboard,
    isLoggedIn: authContext.isLoggedIn,
  };
}
