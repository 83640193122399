import { Tooltip } from "@chakra-ui/react";
import { DateTime } from "luxon";

import { DATE_TIME_FORMAT } from "../../constants/constants";

interface Props {
  date?: Date | null;
  format: string;
  nullText?: string;
}

export function FormattedDateUTC(props: Props) {
  const { date, nullText } = props;
  if (!date) {
    return <span>{nullText ?? ""}</span>;
  }
  const helperText = `${DateTime.fromJSDate(date).toUTC().toFormat(DATE_TIME_FORMAT)} (UTC)`;
  return (
    <span style={{ cursor: "help" }}>
      <Tooltip label={helperText}>
        <span>{DateTime.fromJSDate(date).toUTC().toFormat(props.format)}</span>
      </Tooltip>
    </span>
  );
}
