import { CREATE_CLIENT_SCREENS, useCreateClient } from "./use-create-client";
import { CreateClientWebsite } from "./create-client-website";
import { CreateClientContact } from "./create-client-contact";

interface Props {
  onAdded: () => void;
  partnerId: number;
}

export function CreateClient(props: Props) {
  const { currentScreen, formik, onNext, onSave } = useCreateClient(props);

  return (
    <>
      {currentScreen === CREATE_CLIENT_SCREENS.WEBSITE && <CreateClientWebsite formik={formik} onNext={onNext} />}
      {currentScreen === CREATE_CLIENT_SCREENS.CONTACT && <CreateClientContact formik={formik} onSave={onSave} />}
    </>
  );
}
