import React, { useState } from "react";
import { Box } from "@chakra-ui/react";

import { TableContext } from "./table-context";

interface Props {
  children: React.ReactNode;
  isLoading: boolean;
  isEmpty: boolean;
  emptyText?: string;
}

export function Table(props: Props) {
  const { children, isLoading, isEmpty, emptyText } = props;
  const [columnCount, setColumnCount] = useState(0);

  return (
    <TableContext.Provider value={{ columnCount, setColumnCount, isLoading, isEmpty, emptyText }}>
      <Box padding={0} width="100%" overflowX={isLoading ? "hidden" : "auto"}>
        <table
          style={{
            width: "100%",
            display: "table",
            borderSpacing: "0",
            borderCollapse: "collapse",
          }}
        >
          {children}
        </table>
      </Box>
    </TableContext.Provider>
  );
}
