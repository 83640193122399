import React from "react";

import { noop } from "../../util/noop";

export interface PanelContextValue {
  isCollapsible: boolean;
  isCollapsed: boolean;
  toggleCollapse: () => void;
}

export const PanelContext = React.createContext<PanelContextValue>({
  isCollapsible: false,
  isCollapsed: false,
  toggleCollapse: noop,
});
