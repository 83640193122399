import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useSso_LoginUserMutation } from "../../generated/graphql";
import { AuthContext } from "../../core/auth-manager";

export function useSso() {
  const [loginUserMutation] = useSso_LoginUserMutation();
  const [showError, setShowError] = useState(false);
  const { code, "*": redirect } = useParams<"code" | "*">();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  useEffect(() => {
    async function login() {
      const loginResponse = await loginUserMutation({
        variables: {
          input: {
            ssoCode: code,
          },
        },
      });

      if (loginResponse.data?.loginUser.isLoggedIn ?? false) {
        await authContext.refresh();
        navigate(redirect ? `/${redirect}` : "/");
      } else {
        await authContext.logout();
        setShowError(true);
      }
    }
    login();
  }, [authContext, loginUserMutation, navigate, code, redirect]);

  return {
    showError,
    code,
  };
}
