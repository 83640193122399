import { Badge, Tooltip } from "@chakra-ui/react";

import { ORDER_STATUS } from "../../constants/order-status";

interface Props {
  status: {
    id: string;
    name: string;
  };
  startDate: string;
}

interface OrderStatusStyle {
  color: string;
}

function getStatusStyling(statusId: string): OrderStatusStyle {
  switch (statusId) {
    case ORDER_STATUS.NEW.id:
    case ORDER_STATUS.SCHEDULED.id:
    case ORDER_STATUS.PENDING.id:
      return { color: "blue" };
    case ORDER_STATUS.IN_PROGRESS.id:
      return { color: "yellow" };
    case ORDER_STATUS.COMPLETED.id:
      return { color: "green" };
    case ORDER_STATUS.CANCELLED.id:
      return { color: "red" };
    case ORDER_STATUS.PAUSED.id:
      return { color: "orange" };
    default:
      return { color: "black" };
  }
}

export function OrderStatus(props: Props) {
  const { status, startDate } = props;
  const { color } = getStatusStyling(status.id);
  const indicator = <Badge colorScheme={color}>{status.name}</Badge>;

  if (status.id === ORDER_STATUS.SCHEDULED.id) {
    return (
      <Tooltip label={startDate} aria-label={startDate}>
        {indicator}
      </Tooltip>
    );
  } else {
    return indicator;
  }
}
