import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { FormikHelpers, useFormik } from "formik";
import * as yup from "yup";

import { useResetPasswordMutation } from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";

const resetPasswordSchema = yup.object().shape({
  password: yup.string().label("Password").required(),
  confirmPassword: yup
    .string()
    .label("Confirm Password")
    .required()
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
});

interface FormValues {
  password: string;
  confirmPassword: string;
}

export function useResetPassword() {
  const [resetPassword] = useResetPasswordMutation();
  const toast = useToast();
  const history = useNavigate();
  const { id } = useParams<"id">();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function onSubmit(values: FormValues, _formikHelpers: FormikHelpers<FormValues>) {
    try {
      const response = await resetPassword({
        variables: {
          input: {
            id: id ?? "",
            password: values.password,
          },
        },
      });
      if (response?.data?.resetPassword?.wasReset) {
        history("/login");
      } else {
        throw new Error("There was a problem resetting password. Please try again.");
      }
    } catch (e: any) {
      toast({ title: "Reset Password", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validateOnBlur: true,
    validationSchema: resetPasswordSchema,
    onSubmit,
  });

  return {
    formik,
  };
}
