import { Textarea, Menu, MenuList, MenuItem, MenuButton, Stack, Box, Button, Text } from "@chakra-ui/react";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

import { Ui_ProposedKeywordFragment, RejectedKeyword } from "../../../../generated/graphql";
import AppIcon from "../../../../components/app-icon";
import { TableRow, TableCell } from "../../../../components/table";
import { CancelButton, SecondaryButton } from "../../../../components/buttons";
import { AppColors } from "../../../../core/custom-theme";
import { useProposedKeywordRow } from "./use-proposed-keyword-row";

interface Props {
  proposedKeyword: Ui_ProposedKeywordFragment;
  onRejected: (proposedCustomerKeywordId: number, reason: string) => void;
  onUndo: (proposedCustomerKeywordId: number) => void;
  rejectDisabled?: boolean;
  rejectedKeyword: RejectedKeyword | null;
}

export function ProposedKeywordRow(props: Props) {
  const { proposedKeyword, onRejected, onUndo, rejectDisabled, rejectedKeyword } = props;

  const { showRejected, reason, onReject, onSave, onCancel, onReasonChange, isRejected } = useProposedKeywordRow({
    proposedKeyword,
    onRejected,
    rejectedKeyword,
  });

  function renderAction() {
    if (!isRejected && !showRejected && !rejectDisabled) {
      return (
        <Menu autoSelect={false}>
          <MenuButton as={Button} {...{ variant: "ghost" }}>
            <AppIcon icon={faEllipsisV} />
          </MenuButton>
          <MenuList>
            <MenuItem onClick={onReject}>Revise</MenuItem>
          </MenuList>
        </Menu>
      );
    }

    if (showRejected) {
      return (
        <>
          <Textarea
            onChange={onReasonChange}
            value={reason}
            resize="none"
            marginBottom={2}
            placeholder="Please explain why this keyword should be revised"
          />
          <Stack isInline spacing={2} justifyContent="flex-end">
            <SecondaryButton size="sm" onClick={onSave}>
              Save
            </SecondaryButton>
            <CancelButton size="sm" onClick={onCancel}>
              Cancel
            </CancelButton>
          </Stack>
        </>
      );
    }

    if (isRejected) {
      return (
        <Stack isInline spacing={2} justifyContent="flex-end" alignItems="center">
          <Text fontSize="sm" color={AppColors.red}>
            Revision Requested
          </Text>
          <Menu autoSelect={false}>
            <MenuButton as={Button} {...{ variant: "ghost" }}>
              <AppIcon icon={faEllipsisV} />
            </MenuButton>
            <MenuList>
              <MenuItem onClick={onReject}>Edit Revision Request</MenuItem>
              <MenuItem
                onClick={() => {
                  onUndo(proposedKeyword.id);
                }}
              >
                Remove Revision Request
              </MenuItem>
            </MenuList>
          </Menu>
        </Stack>
      );
    }
  }

  return (
    <TableRow key={proposedKeyword.id}>
      <TableCell>{proposedKeyword.name}</TableCell>
      <TableCell>{proposedKeyword.location ?? ""}</TableCell>
      <TableCell>{proposedKeyword.country ?? ""}</TableCell>
      <TableCell>{proposedKeyword.landingPageUrl ?? ""}</TableCell>
      <TableCell>
        <Box textAlign="right">{renderAction()}</Box>
      </TableCell>
    </TableRow>
  );
}
