import React from "react";
import { Interval } from "luxon";

import { TableCell, TableRow } from "../../components/table";
import { FormattedDateUTC } from "../../components/formatted-date";
import { ClientCampaignStatusIndicator } from "../../common/client-campaign-status-indicator";
import { MMM_D_YYYY } from "../../constants/constants";
import { PackageCustomerCampaignFragment } from "../../generated/graphql";
import { currencyFormatter } from "../../util/formatter";

interface Props {
  campaign?: PackageCustomerCampaignFragment | null;
}

export const Campaign = React.memo(function Campaign(props: Props) {
  const { campaign } = props;

  function renderCampaign(campaign: PackageCustomerCampaignFragment) {
    const campaignInterval = Interval.fromDateTimes(campaign.startsAt, campaign.endsAt);
    const campaignMonths = Math.round(campaignInterval.length("months"));
    return (
      <TableRow key={campaign.id}>
        <TableCell>{campaign.name}</TableCell>
        <TableCell>
          <FormattedDateUTC date={campaign.startsAt} format={MMM_D_YYYY} />
        </TableCell>
        <TableCell>{campaignMonths} months</TableCell>
        <TableCell>{currencyFormatter.format(campaign.amount)}</TableCell>
        <TableCell>
          <ClientCampaignStatusIndicator startsAt={campaign.startsAt} isCurrent={campaign.isCurrent} />
        </TableCell>
      </TableRow>
    );
  }

  return campaign ? renderCampaign(campaign) : null;
});
