import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { Config } from "../config";
import { useStripeProviderQuery } from "../../generated/graphql";

interface Props {
  children: React.ReactNode;
}

// TODO: make this a build time env variable
if (!Config.stripePublishableKey) {
  console.error(`Stripe publishable key not found.`);
}

export function StripeProvider(props: Props) {
  const { children } = props;
  const queryResult = useStripeProviderQuery({
    fetchPolicy: "cache-and-network",
  });

  const partner = queryResult.data?.customer?.partner;
  const stripeAccountId = partner?.stripeAccountId ?? undefined;

  if (!partner) {
    return <>{children}</>;
  }
  if (!stripeAccountId) {
    console.error(`Stripe Account Id not found.`);
  }

  const stripePromise = loadStripe(Config.stripePublishableKey ?? "", { stripeAccount: stripeAccountId });
  return <Elements stripe={stripePromise}>{children}</Elements>;
}
