import React from "react";
import { BoxProps, Box } from "@chakra-ui/react";

interface Props extends BoxProps {
  children: React.ReactNode;
  centerHorizontally?: boolean;
}

export function Container(props: Props) {
  const { children, centerHorizontally, ...rest } = props;
  if (centerHorizontally) {
    return (
      <Box display="flex" justifyContent="center">
        <Box {...rest} width={{ base: "95%", xl: "1120px" }}>
          {children}
        </Box>
      </Box>
    );
  }
  return (
    <Box {...rest} width={{ base: "95%", xl: "1120px" }}>
      {children}
    </Box>
  );
}
