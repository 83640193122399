import { useContext } from "react";
import { DateTime } from "luxon";
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, AreaChart, Area } from "recharts";
import { Box, Text } from "@chakra-ui/react";

import { ErrorCard } from "../../components/error-card";
import { Loader } from "../../components/loader";
import { DATE_TEXT_FORMAT, LOADER_TYPE, MMM_D_YYYY } from "../../constants/constants";
import NoDataAvailable from "../../components/no-data-available";
import { AppColors } from "../../core/custom-theme";
import { Card } from "../../components/card";
import { KeywordRankingContext } from "./keyword-ranking-context";

export function Chart() {
  const { keywordRankingsQueryResult } = useContext(KeywordRankingContext);
  const { loading, data, error } = keywordRankingsQueryResult ?? {};
  const nodes = data?.customerKeywordRankings?.nodes ?? [];
  const graphNodes = nodes.map((node) => ({
    ...node,
    createdAt: node.createdAt.toISOString(),
    rangeValue: [100, node.rank],
  }));

  const lowestRank = Math.max(...nodes.map((x) => x.rank));

  function CustomTooltip(props: any) {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      const rank = payload[0].payload;
      return (
        <Card>
          <Text>
            <strong>Date</strong>: {DateTime.fromJSDate(new Date(rank.createdAt)).toFormat(MMM_D_YYYY)}
          </Text>
          <Text>
            <strong>Rank</strong>: {rank.rank}
          </Text>
        </Card>
      );
    }
    return null;
  }

  return (
    <Box>
      {loading && <Loader type={LOADER_TYPE.content} />}
      {error && <ErrorCard error={error} />}
      {nodes.length === 0 && <NoDataAvailable />}
      {!loading && !error && nodes.length > 0 && (
        <ResponsiveContainer width="100%" height={200}>
          <AreaChart data={graphNodes} margin={{ top: 10, right: 30, left: 10, bottom: 0 }}>
            <defs>
              <linearGradient id="fillGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#2F80ED" stopOpacity={0.5} />
                <stop offset="100%" stopColor="#2F80ED" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="createdAt"
              type="category"
              tickFormatter={(value) => DateTime.fromJSDate(new Date(value)).toFormat(DATE_TEXT_FORMAT)}
              padding={{ left: 5, right: 5 }}
              tickMargin={5}
            />
            <YAxis
              reversed
              domain={[1, Math.min(lowestRank + 5, 100)]}
              allowDecimals={false}
              tickMargin={5}
              ticks={[0, 1, 25, 50, 100]}
            />
            <Tooltip content={<CustomTooltip />} />
            <Area
              dataKey="rangeValue"
              activeDot
              animationDuration={300}
              stroke={AppColors.primary.main}
              fillOpacity={1}
              fill="url(#fillGradient)"
              type="monotone"
              strokeWidth="2"
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
}
