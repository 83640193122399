import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

import { AuthContext } from "../core/auth-manager";
import { Loader } from "./loader";

interface Props {
  requireCustomer?: boolean;
  children: React.ReactNode;
}

export function RouteAuthorization(props: Props) {
  const authContext = useContext(AuthContext);

  if (authContext.isLoading) {
    return <Loader />;
  }

  if (!authContext.isLoggedIn) {
    return <Navigate to="/login" />;
  }

  if (props.requireCustomer === true && !authContext.customer) {
    return <Navigate to="/login" />;
  }

  return <>{props.children}</>;
}
