import { ButtonProps, Button } from "@chakra-ui/react";
import Color from "color";

import { AppColors } from "../../core/custom-theme";

export function DangerButton(props: ButtonProps) {
  return (
    <Button
      variant="outline"
      borderColor={AppColors.red}
      color={AppColors.red}
      _hover={{
        bg: AppColors.red,
        color: "white",
      }}
      _active={{
        bg: Color(AppColors.red).darken(0.2).hex(),
      }}
      fontSize="14px"
      paddingX="30px"
      paddingY="10px"
      {...props}
    />
  );
}
