import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { useConversationQuery, useCreateMessageMutation } from "../../generated/graphql";
import { handleFormGraphQLError } from "../../util/error-helper";

export function useConversation() {
  const { conversationId } = useParams<"conversationId">();
  const [content, setContent] = useState("");
  const toast = useToast();

  const queryResult = useConversationQuery({
    variables: {
      conversationId: conversationId ?? "",
    },
    fetchPolicy: "network-only",
    skip: !conversationId,
  });

  const { data } = queryResult;

  const subject = data?.conversation?.subject ?? "";
  const messages = data?.conversation?.messages.nodes ?? [];

  const [createMessage, { loading }] = useCreateMessageMutation();

  function onContentChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setContent(event.target.value);
  }

  async function onPost() {
    if (!conversationId) {
      return;
    }
    try {
      const response = await createMessage({
        variables: {
          input: {
            conversationId,
            content,
          },
        },
      });

      if (response?.data?.createMessage?.message.id) {
        setContent("");
        await queryResult.refetch();
      }
    } catch (e: any) {
      handleFormGraphQLError(e, "Unable to create message", toast);
    }
  }

  function onCancel() {
    setContent("");
  }

  return {
    loading,
    subject,
    content,
    messages,
    onContentChange,
    onPost,
    onCancel,
  };
}
