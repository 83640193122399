import React from "react";
import { BoxProps, Text } from "@chakra-ui/react";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";

import { AppColors } from "../../core/custom-theme";
import AppIcon from "../app-icon";

interface Props extends BoxProps {
  changeAmount: number | null;
}

export const ChangeIndicator = React.memo(function ChangeIndicator(props: Props) {
  const { changeAmount, ...rest } = props;

  if (changeAmount === null) {
    return <Text {...rest}>-</Text>;
  }

  if (changeAmount === 0) {
    return <Text {...rest}>No change</Text>;
  }

  let icon = faArrowUp;
  let color = AppColors.green;
  if (changeAmount < 0) {
    color = AppColors.red;
    icon = faArrowDown;
  }

  return (
    <Text color={color} {...rest}>
      <AppIcon icon={icon} standardRightMargin />
      {changeAmount.toFixed(0)}
    </Text>
  );
});
