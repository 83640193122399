import { useCustomerAnalyticsQuery } from "../../generated/graphql";

export function useAnalytics() {
  const { data, loading } = useCustomerAnalyticsQuery({
    fetchPolicy: "cache-and-network",
  });

  const customerId = data?.customer?.id;
  const src = data?.customer?.analyticsReportingInlineFrameUrl ?? "";

  return {
    loading,
    customerId,
    src,
  };
}
