import {
  Grid,
  FormControl,
  Flex,
  FormLabel,
  Input,
  Heading,
  Stack,
  Textarea,
  List,
  ListItem,
  Text,
  Box,
} from "@chakra-ui/react";

import { LayoutBase, MainNav } from "../../components/layout";
import { PanelGroup, PanelTop, PanelBottom, PanelBox } from "../../components/panels";
import { PrimaryLinkButton, SecondaryButton } from "../../components/buttons";
import { ContentEditor } from "../../components/content-editor";
import { Container } from "../../components/layout/container";
import { useDeliverable } from "./use-deliverable";
import { AppColors } from "../../core/custom-theme";
import { Loader } from "../../components/loader";
import { LOADER_TYPE } from "../../constants/constants";
import { MainNavAnonymous } from "../../components/layout/main-nav-anonymous";
import { htmlDecode } from "../../util/decode";

export function Deliverable() {
  const { delivery, loading, copyToClipboard, isLoggedIn } = useDeliverable();

  if (loading) {
    return <Loader type={LOADER_TYPE.fullView} />;
  }

  return (
    <LayoutBase pageTitle="Content Delivery Instructions">
      {isLoggedIn && <MainNav />}
      {!isLoggedIn && (
        <MainNavAnonymous
          partnerName={delivery?.partnerName ?? ""}
          partnerLogoUrl={delivery?.partnerLogoUrl ?? undefined}
        />
      )}
      <Container centerHorizontally paddingBottom={12} marginTop={8}>
        <Grid gridTemplateColumns={{ base: "1fr", xl: "1fr 1.5fr" }} gridGap={4}>
          <PanelGroup isCollapsible>
            <PanelTop showBottomBorder>
              <Heading size="sm">How To Post &amp; Schedule This Content</Heading>
            </PanelTop>
            <PanelBottom>
              <Stack spacing={6}>
                <List as="ol" styleType="decimal" spacing={4} color={AppColors.textDarkGray}>
                  <ListItem>Open your website blog in a separate window</ListItem>
                  <ListItem>Copy the title only &amp; paste your title into the title field</ListItem>
                  <ListItem>Copy remaining content &amp; paste into the body of your blog post</ListItem>
                  <ListItem>Add any necessary featured images</ListItem>
                  <ListItem>
                    Add any necessary specific information for Meta Title, Meta Description, &amp; Keyword
                  </ListItem>
                  <ListItem>Adjust the author to be published by your preferred author profile</ListItem>
                  <ListItem>Schedule your publication date</ListItem>
                  <ListItem>Plan a date to share to social</ListItem>
                </List>
                <Text fontWeight="bold">OR</Text>
                <Text color={AppColors.textDarkGray}>Send this page&apos;s link to your Content Manager</Text>
                <Box>
                  <SecondaryButton
                    onClick={() => {
                      copyToClipboard(delivery?.publicUrl ?? "");
                    }}
                  >
                    Copy Link
                  </SecondaryButton>
                </Box>
              </Stack>
            </PanelBottom>
          </PanelGroup>
          <PanelBox>
            <Stack spacing={4}>
              <FormControl>
                <Flex justifyContent="space-between">
                  <FormLabel>Title</FormLabel>
                  <PrimaryLinkButton
                    onClick={() => {
                      copyToClipboard(delivery?.title ?? "");
                    }}
                  >
                    Copy
                  </PrimaryLinkButton>
                </Flex>
                <Input size="lg" value={delivery?.title ?? ""} isReadOnly />
              </FormControl>
              <FormControl>
                <Flex justifyContent="space-between">
                  <FormLabel>Content</FormLabel>
                  <Stack spacing={2} isInline>
                    <PrimaryLinkButton
                      onClick={() => {
                        copyToClipboard(htmlDecode(delivery?.contentOnlyText ?? "") ?? "");
                      }}
                    >
                      Copy Text
                    </PrimaryLinkButton>
                    <PrimaryLinkButton
                      onClick={() => {
                        copyToClipboard(delivery?.content ?? "");
                      }}
                    >
                      Copy HTML
                    </PrimaryLinkButton>
                  </Stack>
                </Flex>
                {!loading && delivery && <ContentEditor initialValue={delivery?.content ?? ""} readOnly height={300} />}
              </FormControl>
              <FormControl>
                <Flex justifyContent="space-between">
                  <FormLabel>Meta Title</FormLabel>
                  <PrimaryLinkButton
                    onClick={() => {
                      copyToClipboard(delivery?.metaTitle ?? "");
                    }}
                  >
                    Copy
                  </PrimaryLinkButton>
                </Flex>
                <Input value={delivery?.metaTitle ?? ""} isReadOnly />
              </FormControl>
              <FormControl>
                <Flex justifyContent="space-between">
                  <FormLabel>Meta Description</FormLabel>
                  <PrimaryLinkButton
                    onClick={() => {
                      copyToClipboard(htmlDecode(delivery?.metaDescription ?? "") ?? "");
                    }}
                  >
                    Copy
                  </PrimaryLinkButton>
                </Flex>
                <Textarea value={htmlDecode(delivery?.metaDescription ?? "") ?? ""} isReadOnly />
              </FormControl>
              <FormControl>
                <Flex justifyContent="space-between">
                  <FormLabel>Keyword</FormLabel>
                  <PrimaryLinkButton
                    onClick={() => {
                      copyToClipboard(delivery?.keyword ?? "");
                    }}
                  >
                    Copy
                  </PrimaryLinkButton>
                </Flex>
                <Input value={delivery?.keyword ?? ""} isReadOnly />
              </FormControl>
              {(delivery?.feedbackContentItems ?? []).length > 0 && (
                <FormControl>
                  <FormLabel>Feedback</FormLabel>
                  <ContentEditor
                    initialValue={delivery?.feedbackContentItems?.[0].feedback ?? ""}
                    readOnly
                    height={300}
                  />
                </FormControl>
              )}
            </Stack>
          </PanelBox>
        </Grid>
      </Container>
    </LayoutBase>
  );
}
