import React from "react";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
}

function SearchBox(props: Props) {
  const { onChange, placeholder } = props;
  return (
    <InputGroup size="md">
      <Input pr="3rem" placeholder={placeholder} onChange={onChange} />
      <InputRightElement width="3rem">
        <SearchIcon />
      </InputRightElement>
    </InputGroup>
  );
}

export default SearchBox;
