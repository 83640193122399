import { Text } from "@chakra-ui/react";
import { ApolloError } from "@apollo/client";

interface Props {
  error?: ApolloError;
}

export function ErrorCard(props: Props) {
  if (props.error && props.error.message) {
    return (
      <Text fontSize="lg" textAlign={["left", "center"]}>
        {props.error.message}
      </Text>
    );
  } else {
    return (
      <Text fontSize="lg" textAlign={["left", "center"]}>
        Something went wrong!!
      </Text>
    );
  }
}
