import { Box, Image, Text } from "@chakra-ui/react";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";

import AppIcon from "../../app-icon";
import { AppColors } from "../../../core/custom-theme";
import { MAIN_NAV_HEIGHT } from "../../../constants/constants";

interface Props {
  hasSecondaryNav?: boolean;
  partnerName?: string;
  partnerLogoUrl?: string;
}

export function MainNavFullAnonymous(props: Props) {
  const { hasSecondaryNav, partnerName, partnerLogoUrl } = props;

  return (
    <Box
      height={MAIN_NAV_HEIGHT}
      backgroundColor={AppColors.textNormal}
      display="grid"
      gridTemplateColumns="minmax(0px, 1fr) minmax(300px, 1120px) minmax(0px, 1fr)"
      justifyContent="stretch"
      alignItems="center"
      position="sticky"
      top="0"
      zIndex={999}
      marginBottom={hasSecondaryNav === true ? 0 : 2}
      boxShadow={hasSecondaryNav === true ? undefined : "2px 0px 12px rgba(0, 0, 0, 0.6)"}
    >
      <Box gridColumn="1">
        <Box paddingLeft={4}>
          {!partnerLogoUrl && (
            <Text marginLeft={2} color={AppColors.white}>
              <AppIcon icon={faChartLine} standardRightMargin />
              {partnerName ?? "Launch Pad"}
            </Text>
          )}
          {partnerLogoUrl && (
            <Image objectFit="contain" src={partnerLogoUrl} maxHeight={50} maxWidth={200} crossOrigin="" />
          )}
        </Box>
      </Box>
    </Box>
  );
}
