import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Text,
  Textarea,
  Box,
} from "@chakra-ui/react";

import { PrimaryButton } from "../../components/buttons";
import { useConversationCreateDialog } from "./use-conversation-create-dialog";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export function ConversationCreateDialog(props: Props) {
  const { isOpen, onClose } = props;

  const { loading, subject, message, errorSubject, onChange, onMessageChange, onSend } = useConversationCreateDialog({
    onClose,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New Message</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb="8px">Subject</Text>
          <Input
            isInvalid={errorSubject.length > 0}
            errorBorderColor="crimson"
            pr="4.5rem"
            value={subject}
            onChange={onChange}
            my={1}
            autoFocus
          />
          {errorSubject.length > 0 && (
            <Text fontSize="sm" color="crimson" my={1}>
              {errorSubject}
            </Text>
          )}
          <Box my={1}>
            <Text mb="8px">Message</Text>
            <Textarea onChange={onMessageChange} resize="none" />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose} size="sm">
            Cancel
          </Button>
          <PrimaryButton
            size="sm"
            variant="solid"
            colorScheme="blue"
            isDisabled={
              loading || subject.trim().length === 0 || message.trim().length === 0
            } /*  TODO: restriction on message length */
            onClick={onSend}
          >
            Send
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
