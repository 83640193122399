import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { useFormik } from "formik";

import {
  PartnerPackages_PackageFragment,
  useCreateCustomerCampaignMutation,
  usePartnerPackagesLazyQuery,
  usePackageInvoiceLazyQuery,
} from "../../generated/graphql";
import { handleFormGraphQLError, handleGraphQLResponseError } from "../../util/error-helper";
import { CAMPAIGN_CATEGORIES } from "../../constants/campaign-category";
import { useAuthContext } from "../../core/auth-manager/auth-manager-hook";
import { CUSTOMER_CAMPAIGN_INVOICE_STATUS } from "../../constants/customer-campaign-invoice-status";

interface FormValues {
  customerMarketSize: string;
  isCallRequired: boolean;
}

const schema = yup.object().shape({
  customerMarketSize: yup.string().label("Customer Market Size").required(),
  isCallRequired: yup.boolean().label("Is Monthly Call Required").required().default(false),
});

export function usePartnerPackages() {
  const toast = useToast();
  const history = useNavigate();
  const authContext = useAuthContext();
  const [selectedPackage, setSelectedPackage] = useState<PartnerPackages_PackageFragment>();
  const partnerId = authContext.customer?.partner?.id ?? 0;
  const [getPartnerPackages, { loading, data }] = usePartnerPackagesLazyQuery({
    fetchPolicy: "network-only",
    variables: {
      partnerId,
    },
  });

  const [createCustomerCampaign, { loading: createCampaignLoading }] = useCreateCustomerCampaignMutation();

  const [getInvoice, { data: invoiceData }] = usePackageInvoiceLazyQuery({
    pollInterval: 100,
  });

  const invoiceId = invoiceData?.customer?.customerCampaignInvoices?.nodes?.[0]?.id;

  useEffect(() => {
    if (invoiceId) {
      history(`/invoices/${invoiceId}`);
    }
  }, [history, invoiceId]);

  // This is placeholder function not used for network calls
  function onSubmit() {
    getPartnerPackages();
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      customerMarketSize: "",
      isCallRequired: false,
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit,
  });

  useEffect(() => {
    // This search is based on naming used for packages which can lead to filtering or not filtering regular packages
    if (formik.values.customerMarketSize) {
      getPartnerPackages({
        variables: {
          partnerId,
          search1: formik.values.customerMarketSize,
          search2: formik.values.isCallRequired ? "AM" : "",
          notInSearch: formik.values.isCallRequired ? "" : "AM",
        },
      });
    }
  }, [formik.values.customerMarketSize, formik.values.isCallRequired, getPartnerPackages, partnerId]);

  function onCustomerMarketSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
    formik.handleChange(e);
  }

  function onIsCallRequiredChange(value: string) {
    formik.setFieldValue("isCallRequired", value === "yes");
  }

  function onSelectPackage(partnerPackage: PartnerPackages_PackageFragment) {
    setSelectedPackage(partnerPackage);
  }

  async function onSave() {
    try {
      const response = await createCustomerCampaign({
        variables: {
          input: {
            name: selectedPackage?.name ?? "",
            startsAt: DateTime.now().plus({ day: 1 }).toJSDate(),
            customerId: authContext.customer?.id ?? 0,
            campaignCategoryId: CAMPAIGN_CATEGORIES.SEO.id,
            partnerPackageId: selectedPackage?.id ?? 0,
          },
        },
      });

      if (response.data?.createCustomerCampaign.ok) {
        getInvoice({
          variables: {
            invoiceDate: {
              gt: DateTime.now().minus({ hour: 1 }).toJSDate(),
            },
            statusIds: [CUSTOMER_CAMPAIGN_INVOICE_STATUS.OPEN.id],
          },
        });
      } else {
        if (response.data?.createCustomerCampaign.error) {
          handleGraphQLResponseError(response.data.createCustomerCampaign.error, toast);
        } else {
          throw new Error("Something went wrong!");
        }
      }
    } catch (e: any) {
      handleFormGraphQLError(e, "Unable to Save Client", toast);
    }
  }

  return {
    loading,
    packages: data?.partnerPackages.nodes ?? [],
    formik,
    onCustomerMarketSizeChange,
    onIsCallRequiredChange,
    onSelectPackage,
    selectedPackage,
    onSave,
    createCampaignLoading,
  };
}
