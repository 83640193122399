import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import { FormikHelpers, useFormik } from "formik";
import * as yup from "yup";

import { useCreateCustomerMutation } from "../../../generated/graphql";
import { handleFormGraphQLError, handleGraphQLResponseError } from "../../../util/error-helper";
import { APP_NAME } from "../../../constants/constants";

export const CREATE_CLIENT_SCREENS = {
  WEBSITE: "website",
  CONTACT: "contact",
};

interface Options {
  partnerId: number;
  onAdded: () => void;
}

export interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  name: string;
  website: string;
  categoryId: number;
}

const createCustomerSchema = yup.object().shape({
  firstName: yup.string().label("First Name").required().max(255),
  lastName: yup.string().label("Last Name").required().max(255),
  email: yup.string().label("Email").required().email().max(255),
  name: yup.string().label("Company Name").required(),
  website: yup.string().label("Website").url().required(),
  categoryId: yup
    .number()
    .label("Business Category")
    .required()
    .nullable(false)
    .min(1, "Business Category is a required field"),
});

export function useCreateClient(options: Options) {
  const { onAdded, partnerId } = options;
  const [currentScreen, setCurrentScreen] = useState(CREATE_CLIENT_SCREENS.WEBSITE);
  const [createCustomerMutation] = useCreateCustomerMutation();
  const toast = useToast();

  async function onSubmit(values: FormValues, helpers: FormikHelpers<FormValues>) {
    try {
      const response = await createCustomerMutation({
        variables: {
          input: {
            partnerId,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            name: values.name,
            website: values.website,
            categoryId: values.categoryId,
            portal: APP_NAME,
          },
        },
      });

      if (response.data?.createCustomer.ok) {
        toast({ title: "Client added", description: `${values.name} was added successfully.`, status: "success" });
        onAdded();
      } else {
        if (response.data?.createCustomer.error) {
          handleGraphQLResponseError(response.data.createCustomer.error, toast, helpers.setErrors);
        } else {
          throw new Error("Something went wrong!");
        }
      }
    } catch (e: any) {
      handleFormGraphQLError(e, "Unable to Save Client", toast, helpers.setErrors);
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      name: "",
      website: "",
      categoryId: 0,
    },
    validationSchema: createCustomerSchema,
    onSubmit,
  });

  function onNext() {
    setCurrentScreen(CREATE_CLIENT_SCREENS.CONTACT);
  }

  function onSave() {
    if (formik.isValid) {
      formik.handleSubmit();
    } else {
      formik.setErrors(formik.errors);
      setCurrentScreen(CREATE_CLIENT_SCREENS.WEBSITE);
    }
  }

  return {
    formik,
    onSave,
    currentScreen,
    onNext,
  };
}
