import { Box, FormControl, FormLabel, Input, FormErrorMessage, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

import { AppColors } from "../../core/custom-theme";
import { PrimaryButton } from "../../components/buttons";
import { useResetPassword } from "./use-reset-password";

export function ResetPassword() {
  const { formik } = useResetPassword();
  return (
    <>
      <Helmet title="Request Password Reset" />
      <Box
        bg={AppColors.body}
        display="flex"
        justifyContent="center"
        alignItems={{ xs: "flex-start", lg: "center" }}
        height="100vh"
      >
        <Box p={4} mt={1} width="30%" borderRadius={4}>
          {/* TODO: Change Logo
          <Flex justifyContent="center" marginBottom={4}>
            <Image
              justifyContent="center"
              src={Logo}
              alt="Creatives Scale"
              width="250px"
              height="100px"
              objectFit="contain"
            />
          </Flex>
          */}
          <Box
            padding={8}
            marginBottom={2}
            textAlign="left"
            bg={AppColors.white}
            borderRadius={4}
            border={`1px solid ${AppColors.whiteSmoke}`}
          >
            <Text fontWeight={600} color={AppColors.textNormal} fontSize={24} mb={8}>
              Password Reset
            </Text>
            <form onSubmit={formik.handleSubmit} noValidate={true}>
              <FormControl isInvalid={!!formik.errors.password && formik.touched.password} mb={2}>
                <FormLabel fontSize={14} fontWeight="normal">
                  New Password
                </FormLabel>
                <Input
                  type="password"
                  id="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  autoFocus
                />
                {formik.touched.password && formik.errors.password && (
                  <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!formik.errors.confirmPassword && formik.touched.confirmPassword}>
                <FormLabel fontSize={14} fontWeight="normal">
                  Confirm New Password
                </FormLabel>
                <Input
                  type="password"
                  id="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                />
                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                  <FormErrorMessage>{formik.errors.confirmPassword}</FormErrorMessage>
                )}
              </FormControl>
              <Box mt={8}>
                <PrimaryButton
                  width="100%"
                  bg={AppColors.textNormal}
                  _hover={{ bg: AppColors.textNormal }}
                  _active={{ bg: AppColors.textNormal }}
                  type="submit"
                  isDisabled={formik.isSubmitting}
                  isLoading={formik.isSubmitting}
                  loadingText="Resetting Password..."
                >
                  Set Password
                </PrimaryButton>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
}
