import { Box, Heading, Stack, Text } from "@chakra-ui/react";

import { PanelBottom, PanelBox, PanelGroup, PanelMiddle, PanelTop } from "../../../components/panels";
import { PrimaryButton } from "../../../components/buttons";

interface Props {
  onSEOGetStarted: () => void;
}

export function SeoIntroduction(props: Props) {
  const { onSEOGetStarted } = props;

  return (
    <PanelGroup>
      <PanelTop showBottomBorder={false}>
        <Heading textAlign="center">
          <Text>Need More Customers? Try SEO.</Text>
          <Text>(search engine optimization)</Text>
        </Heading>
      </PanelTop>
      <PanelMiddle>
        <Text fontSize="3xl" textAlign="center">
          Did you Know?
        </Text>
        <Stack isInline spacing={2}>
          <PanelBox width="100%">
            <Text>
              <strong>81%</strong> of consumers use a Search Engine like Google before making a purchase.
            </Text>
          </PanelBox>
          <PanelBox width="100%">
            <Text>
              <strong>70%</strong> of those consumers will click on the first <strong>5</strong> listings
            </Text>
          </PanelBox>
          <PanelBox width="100%">
            <Text>SEO has the highest ROI(Return on Investment) compared to other marketing methods.</Text>
          </PanelBox>
        </Stack>
      </PanelMiddle>
      <PanelBottom>
        <Heading textAlign="center">
          <Text>How do we help you?</Text>
          <Text>Google E.A.T.</Text>
        </Heading>
        <Stack isInline spacing={2}>
          <Box width="100%" padding={4}>
            <Text>
              <strong>E</strong>xpertise: Does your content give potential customers what they are looking for. Does it
              answer questions and explain your product or service well?
            </Text>
          </Box>
          <Box width="100%" padding={4}>
            <Text>
              <strong>A</strong>uthority: Is your content good enough for other websites to link to your site? Authority
              is given from other website owners.
            </Text>
          </Box>
          <Box width="100%" padding={4}>
            <Text>
              <strong>T</strong>rust: Is your site secure and safe. Is your business information consistent across the
              internet?
            </Text>
          </Box>
        </Stack>
        <PrimaryButton onClick={onSEOGetStarted} marginTop={4}>
          Get Started
        </PrimaryButton>
      </PanelBottom>
    </PanelGroup>
  );
}
