import React from "react";
import { Box, Flex, Link, List, Text, theme, Tooltip } from "@chakra-ui/react";
import { DateTime } from "luxon";
import MarkdownIt from "markdown-it";

import { getRelativeTimeDisplayText } from "../../util/datetime-helpers";
import { deserializeMessageHtml } from "./message-html-deserializer";
import { DATE_TIME_FORMAT } from "../../constants/constants";
import { MESSAGE_CONTENT_ELEMENTS } from "./message-content-elements";
import { AppColors } from "../../core/custom-theme";

interface Props {
  userName: string;
  createdAt: Date;
  content: string;
  isFromClient?: boolean;
}

const mdParser = new MarkdownIt({
  html: true,
});

// TODO: Change type to Node
function renderElement(node: any): React.ReactNode {
  if (node.text) {
    return (node.text as string) ?? "";
  }
  const children = Array.isArray(node.children) ? (node.children as any[]).map(renderElement) : "";

  switch (node.type) {
    case MESSAGE_CONTENT_ELEMENTS.BLOCK_QUOTE:
      return (
        <Box borderLeft={`3px solid ${AppColors.primary.main}`} paddingLeft={4} marginBottom={4}>
          {children}
        </Box>
      );
    case MESSAGE_CONTENT_ELEMENTS.BULLETED_LIST:
      return (
        <List styleType="disc" marginBottom={4}>
          {children}
        </List>
      );
    case MESSAGE_CONTENT_ELEMENTS.NUMBERED_LIST:
      return (
        <List as="ol" styleType="decimal" marginBottom={4}>
          {children}
        </List>
      );
    case MESSAGE_CONTENT_ELEMENTS.HEADING_1:
      return (
        <Text as="h1" fontSize="3xl" marginBottom={4}>
          {children}
        </Text>
      );
    case MESSAGE_CONTENT_ELEMENTS.HEADING_2:
      return (
        <Text as="h2" fontSize="2xl" marginBottom={4}>
          {children}
        </Text>
      );
    case MESSAGE_CONTENT_ELEMENTS.HEADING_3:
      return (
        <Text as="h3" fontSize="xl" marginBottom={4}>
          {children}
        </Text>
      );
    case MESSAGE_CONTENT_ELEMENTS.HEADING_4:
      return (
        <Text as="h4" fontSize="lg" marginBottom={4}>
          {children}
        </Text>
      );
    case MESSAGE_CONTENT_ELEMENTS.HEADING_5:
      return (
        <Text as="h5" marginBottom={4}>
          {children}
        </Text>
      );
    case MESSAGE_CONTENT_ELEMENTS.HEADING_6:
      return (
        <Text as="h6" fontSize="sm" marginBottom={4}>
          {children}
        </Text>
      );
    case MESSAGE_CONTENT_ELEMENTS.LIST_ITEM:
      return <li>{children}</li>;
    case MESSAGE_CONTENT_ELEMENTS.LINK:
      return (
        <Link color={AppColors.linkBlue} href={node.url as string}>
          {children}
        </Link>
      );
    case MESSAGE_CONTENT_ELEMENTS.STRONG:
      return <strong>{children}</strong>;
    default:
      return <Text marginBottom={4}>{children}</Text>;
  }
}

function renderMessageContent(content: string): React.ReactNode {
  const deserialized = deserializeMessageHtml(content);
  return <>{deserialized.map(renderElement)}</>;
}

export function MessageItem(props: Props) {
  const { createdAt, content, isFromClient } = props;
  const isHtml = content.trim().startsWith("<") && content.trim().endsWith(">");

  return (
    <Flex justifyContent={isFromClient ? "flex-end" : "flex-start"}>
      <Box
        padding={8}
        backgroundColor={isFromClient ? AppColors.white : AppColors.messageBackground}
        width={{ base: "75%", xl: "65%" }}
        borderRadius={4}
        marginBottom={8}
      >
        {isHtml && renderMessageContent(content)}
        {!isHtml && <Box padding={2} dangerouslySetInnerHTML={{ __html: mdParser.render(content) }} />}
        <Box textAlign="right" marginTop={4}>
          <Tooltip
            aria-label={DateTime.fromJSDate(createdAt).toFormat(DATE_TIME_FORMAT)}
            label={DateTime.fromJSDate(createdAt).toFormat(DATE_TIME_FORMAT)}
          >
            <span>
              <Text fontSize="sm" color={theme.colors.gray[500]}>
                {getRelativeTimeDisplayText(createdAt)}
              </Text>
            </span>
          </Tooltip>
        </Box>
      </Box>
    </Flex>
  );
}
