import { Box, Grid } from "@chakra-ui/react";

import { LayoutBase } from "../../components/layout";
import { SeoIntroduction } from "./seo-introduction/seo-introduction";
import { SCREEN, useSignup } from "./use-signup";
import { CreateClient } from "./create-client";
import { Success } from "./success/success";

export function Signup() {
  const { currentScreen, onSEOGetStarted, partnerId, onClientAdded } = useSignup();

  return (
    <LayoutBase pageTitle="Signup">
      <Box
        display="flex"
        justifyContent="center"
        alignItems={["flex-start", "center"]}
        height="100vh"
        paddingBottom={8}
      >
        <Grid width={currentScreen === SCREEN.SEO_INTRODUCTION ? "60%" : "40%"}>
          {currentScreen === SCREEN.SEO_INTRODUCTION && <SeoIntroduction onSEOGetStarted={onSEOGetStarted} />}
          {currentScreen === SCREEN.CREATE_CUSTOMER && <CreateClient onAdded={onClientAdded} partnerId={partnerId} />}
          {currentScreen === SCREEN.SUCCESS && <Success />}
        </Grid>
      </Box>
    </LayoutBase>
  );
}
