import { NumberParam, StringParam, useQueryParam, withDefault } from "use-query-params";
import { LAUNCHPAD_PARTNER_ID } from "../../constants/constants";

export const SCREEN = {
  SEO_INTRODUCTION: "seo-introduction",
  CREATE_CUSTOMER: "create-customer",
  SPEND_SEO: "spend-seo",
  SUCCESS: "success",
};

export function useSignup() {
  const [currentScreen, setCurrentScreen] = useQueryParam("screen", withDefault(StringParam, SCREEN.SEO_INTRODUCTION));
  const [partnerId] = useQueryParam("partner", withDefault(NumberParam, LAUNCHPAD_PARTNER_ID));

  function onSEOGetStarted() {
    setCurrentScreen(SCREEN.CREATE_CUSTOMER);
  }

  function onClientAdded() {
    setCurrentScreen(SCREEN.SUCCESS);
  }

  function onContinueSpend() {
    setCurrentScreen(SCREEN.SUCCESS);
  }

  return {
    currentScreen,
    onSEOGetStarted,
    partnerId,
    onClientAdded,
    onContinueSpend,
  };
}
