import React, { useContext } from "react";
import { Flex, Heading, Stack, Image, Text, Link, Box } from "@chakra-ui/react";

import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";
import { AuthContext } from "../../core/auth-manager";
import { PrimaryButtonLink } from "../../components/buttons";
import { AppColors } from "../../core/custom-theme";

export const DashboardMeeting = React.memo(function DashboardMeeting() {
  const authValue = useContext(AuthContext);
  const { customer } = authValue;

  if (!customer?.accountManagerUser) {
    return null;
  }

  return (
    <PanelGroup>
      <PanelTop showBottomBorder>
        <Heading size="md">{customer.accountManagerUser?.title ?? "Brand Manager"}</Heading>
      </PanelTop>
      <PanelBottom>
        <Stack spacing={4}>
          <Flex alignItems="center">
            {customer.accountManagerUser.profilePictureFile && (
              <Image
                borderRadius="full"
                boxSize="100px"
                src={customer.accountManagerUser.profilePictureFile?.url}
                alt={customer.accountManagerUser.profilePictureFile?.name}
                mr={2}
                crossOrigin=""
              />
            )}
            <Box>
              <Text fontSize="lg" fontWeight="bold">
                {customer.accountManagerUser.fullName}
              </Text>
            </Box>
          </Flex>
          {customer.accountManagerUser.calendarUrl && (
            <Flex>
              <Link
                textDecor="none"
                _hover={{ textDecor: "none" }}
                color={AppColors.primary.main}
                href={customer.accountManagerUser.calendarUrl}
                isExternal
              >
                Book a Meeting
              </Link>
            </Flex>
          )}
          <Flex>
            <PrimaryButtonLink href="/messages">Message</PrimaryButtonLink>
          </Flex>
        </Stack>
      </PanelBottom>
    </PanelGroup>
  );
});
