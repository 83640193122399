import { Stack, FormControl, FormLabel, Input, FormErrorMessage, Heading } from "@chakra-ui/react";
import { FormikContextType } from "formik";

import { PrimaryButton } from "../../../components/buttons";
import { CategorySelector } from "../../../common/category-selector";
import { PanelBottom, PanelGroup, PanelMiddle, PanelTop } from "../../../components/panels";
import { FormValues } from "./use-create-client";

interface Props {
  formik: FormikContextType<FormValues>;
  onSave: () => void;
}

export function CreateClientContact(props: Props) {
  const { formik, onSave } = props;

  return (
    <PanelGroup>
      <PanelTop isHeader>
        <Heading>Create Client</Heading>
      </PanelTop>
      <PanelMiddle>
        <Stack spacing={4}>
          <FormControl isRequired isInvalid={!!formik.errors.firstName}>
            <FormLabel>Primary Contact First Name</FormLabel>
            <Input
              autoFocus
              variant="outline"
              value={formik.values.firstName}
              id="firstName"
              onChange={formik.handleChange}
            />
            <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={!!formik.errors.lastName}>
            <FormLabel>Primary Contact Last Name</FormLabel>
            <Input variant="outline" value={formik.values.lastName} id="lastName" onChange={formik.handleChange} />
            <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={!!formik.errors.name}>
            <FormLabel>Company Name</FormLabel>
            <Input variant="outline" value={formik.values.name} id="name" onChange={formik.handleChange} />
            <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={!!formik.errors.categoryId}>
            <FormLabel>Business Category</FormLabel>
            <CategorySelector
              placeholder="Select a Category"
              variant="outline"
              selectedCategoryId={formik.values.categoryId}
              onCategorySelected={(categoryId) => {
                formik.setFieldValue("categoryId", categoryId);
              }}
            />
            <FormErrorMessage>{formik.errors.categoryId}</FormErrorMessage>
          </FormControl>
        </Stack>
      </PanelMiddle>
      <PanelBottom>
        <PrimaryButton onClick={onSave} isDisabled={formik.isSubmitting} isLoading={formik.isSubmitting}>
          Get started
        </PrimaryButton>
      </PanelBottom>
    </PanelGroup>
  );
}
