import React from "react";
import { Link as RouterLink, LinkProps as RRDLinkProps } from "react-router-dom";
import { Link, LinkProps as CUILinkProps } from "@chakra-ui/react";

import { AppColors } from "../../../core/custom-theme";

interface Props extends CUILinkProps {
  to: string;
  children: React.ReactNode;
}

function getStyles(): CUILinkProps {
  return {
    color: AppColors.linkDarkGray,
    textDecoration: "none",
    fontSize: "16px",
    fontWeight: "bold",
    _focus: {
      textDecoration: "none",
      color: AppColors.linkDarkGray,
    },
    _hover: {
      color: AppColors.lightGray,
    },
  };
}

/* AppLink is a wrapper adding custom styling to React Routers Link component */
export function MainNavLink(props: Props) {
  const { to, children, ...rest } = props;
  const isUpdateAvailable = false;
  if (isUpdateAvailable) {
    return (
      <Link {...getStyles()} href={to} {...rest}>
        {children}
      </Link>
    );
  }

  return (
    <RouterLink {...(getStyles() as Omit<RRDLinkProps, "to">)} to={to} {...(rest as Omit<RRDLinkProps, "to">)}>
      {children}
    </RouterLink>
  );
}
