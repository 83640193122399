import { Stack, FormControl, FormLabel, Input, FormErrorMessage, FormHelperText, Heading } from "@chakra-ui/react";
import { FormikContextType } from "formik";

import { PrimaryButton } from "../../../components/buttons";
import { FormValues } from "./use-create-client";
import { PanelBottom, PanelGroup, PanelMiddle, PanelTop } from "../../../components/panels";

interface Props {
  formik: FormikContextType<FormValues>;
  onNext: () => void;
}

export function CreateClientWebsite(props: Props) {
  const { formik, onNext } = props;
  return (
    <PanelGroup>
      <PanelTop isHeader>
        <Heading>Create Client</Heading>
      </PanelTop>
      <PanelMiddle>
        <Stack spacing={4}>
          <FormControl isRequired isInvalid={!!formik.errors.email}>
            <FormLabel>Primary Contact Email</FormLabel>
            <Input variant="outline" value={formik.values.email} id="email" onChange={formik.handleChange} />
            <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={!!formik.errors.website}>
            <FormLabel>Company URL</FormLabel>
            <Input variant="outline" value={formik.values.website} id="website" onChange={formik.handleChange} />
            <FormHelperText>Must begin with http:// or https://</FormHelperText>
            <FormErrorMessage>{formik.errors.website}</FormErrorMessage>
          </FormControl>
        </Stack>
      </PanelMiddle>
      <PanelBottom>
        <PrimaryButton onClick={onNext} isDisabled={formik.isSubmitting} isLoading={formik.isSubmitting}>
          Next
        </PrimaryButton>
      </PanelBottom>
    </PanelGroup>
  );
}
