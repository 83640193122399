import { Box, Textarea, Heading, Stack } from "@chakra-ui/react";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import AppIcon from "../../components/app-icon";
import { MessageItem } from "./message-item";
import { PageHeading } from "../../components/page-heading";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import AppLink from "../../components/app-link";
import { PrimaryButton, CancelButton } from "../../components/buttons";
import { useConversation } from "./use-conversation";

export function Conversation() {
  const { loading, subject, content, messages, onContentChange, onPost, onCancel } = useConversation();

  return (
    <LayoutBase pageTitle="Messages">
      <MainNav />
      <Container centerHorizontally marginBottom={12}>
        <Box my={4}>
          <AppLink to="/messages">
            <AppIcon icon={faChevronLeft} standardRightMargin />
            Back to Messages
          </AppLink>
        </Box>
        <Box py={2}>
          <PageHeading>{subject}</PageHeading>
        </Box>
        <Box>
          {messages.map((message) => (
            <MessageItem
              isFromClient={message.isFromCustomer}
              key={message.id}
              userName={message.user.fullName}
              createdAt={message.createdAt}
              content={message.content}
            />
          ))}
        </Box>
        <PanelGroup>
          <PanelTop>
            <Heading size="md">Reply</Heading>
          </PanelTop>
          <PanelBottom>
            <Textarea value={content} onChange={onContentChange} resize="none" placeholder="Comments" />
            {content !== "" && (
              <Stack isInline spacing={2} marginTop={2}>
                <PrimaryButton
                  variant="solid"
                  colorScheme="blue"
                  onClick={onPost}
                  isDisabled={!content.trim().length}
                  isLoading={loading}
                >
                  Send
                </PrimaryButton>
                <CancelButton onClick={onCancel}>Cancel</CancelButton>
              </Stack>
            )}
          </PanelBottom>
        </PanelGroup>
      </Container>
    </LayoutBase>
  );
}
