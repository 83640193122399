import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { useCreateConversationMutation } from "../../generated/graphql";
import { TOAST_DURATION, TOAST_POSITION } from "../../constants/constants";
import { handleFormGraphQLError } from "../../util/error-helper";

interface Options {
  onClose: () => void;
}

export function useConversationCreateDialog(options: Options) {
  const { onClose } = options;
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errorSubject, setErrorSubject] = useState("");
  const [createConversation, { loading }] = useCreateConversationMutation();
  const toast = useToast();
  const history = useNavigate();

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    if (value.length > 255) {
      setErrorSubject("Subject length should be less than 255.");
      return;
    } else {
      setErrorSubject("");
    }
    setSubject(value);
  }

  function onMessageChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setMessage(event.target.value);
  }

  async function onSend() {
    try {
      const response = await createConversation({
        variables: {
          input: {
            subject,
            content: message,
          },
        },
      });

      if (response?.data?.createConversation?.conversation?.id) {
        toast({
          title: "Conversation Started",
          position: TOAST_POSITION,
          description: "You have started a conversation", // TODO: Change message
          status: "success",
          duration: TOAST_DURATION,
          isClosable: true,
        });
        onClose();
        history(`/messages/${response.data.createConversation.conversation.id}`);
      }
    } catch (e: any) {
      handleFormGraphQLError(e, "Unable to create conversation", toast);
    }
  }

  return {
    loading,
    subject,
    message,
    errorSubject,
    onChange,
    onMessageChange,
    onSend,
  };
}
