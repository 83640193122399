import { Navigate } from "react-router";
import { Box, Flex, Heading, Stack, Text, useToast, Textarea, Image } from "@chakra-ui/react";
import { faCheckCircle, faInfoCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import { AlertInfo } from "../../../../components/alerts";
import AppIcon from "../../../../components/app-icon";
import {
  CancelButton,
  PrimaryButton,
  PrimaryIconButton,
  SecondaryButton,
  DangerButton,
} from "../../../../components/buttons";
import { ContentEditor } from "../../../../components/content-editor";
import { Loader } from "../../../../components/loader";
import { PanelBottom, PanelGroup, PanelMiddle, PanelTop } from "../../../../components/panels";
import { TOAST_DURATION, TOAST_POSITION } from "../../../../constants/constants";
import { AppColors } from "../../../../core/custom-theme";
import { useContentReview } from "./use-content-review";
import { UploadFileDialog } from "../../../../common/upload-file-modal";

interface Props {
  taskId: number;
}

export function ContentReview(props: Props) {
  const {
    error,
    isSubmitting,
    loading,
    onCancel,
    onChange,
    onReject,
    onSubmit,
    task,
    rejectionComments,
    showReject,
    handleContentChange,
    isContentModified,
    handleCancelModifications,
    content,
    file,
    showThumbnailUpload,
    onThumbnailUpload,
    onThumbnailUploaded,
    onThumbnailUploadCancel,
    onThumbnailRemove,
  } = useContentReview(props);
  const toast = useToast();

  if (loading) {
    return <Loader type="fullView" message="Loading Task" />;
  }

  if (error) {
    toast({
      title: "Error",
      position: TOAST_POSITION,
      description: error.message,
      status: "error",
      duration: TOAST_DURATION,
      isClosable: true,
    });
    return <Navigate to="/" />;
  }

  if (!task || !task?.contentItem?.id) {
    toast({
      title: "Error",
      position: TOAST_POSITION,
      description: "Something went wrong retrieving the task. Please try again.",
      status: "error",
      duration: TOAST_DURATION,
      isClosable: true,
    });
    return <Navigate to="/" />;
  }

  const isCompleted = task.status === "completed";

  return (
    <>
      <UploadFileDialog
        isOpen={showThumbnailUpload}
        onFileUploaded={onThumbnailUploaded}
        onCancel={onThumbnailUploadCancel}
        instructionText="Thumbnail must be a png or jpeg. Recommended size: 600px x 400px or smaller."
      />
      <PanelGroup>
        <PanelTop showBottomBorder>
          <Heading size="md" fontWeight="bold">
            {task.partnerProduct.name}
          </Heading>
          <Text fontSize="sm">{task.partnerProduct.product.description}</Text>
        </PanelTop>
        <PanelMiddle>
          <Heading size="md" fontWeight="bold">
            {task.contentItem.title}
          </Heading>
          <Text fontSize="sm">Title</Text>
        </PanelMiddle>
        <PanelMiddle>
          <ContentEditor
            initialValue={task.contentItem.content}
            height={300}
            onChange={handleContentChange}
            readOnly={isCompleted}
            value={content}
          />
        </PanelMiddle>
        <PanelMiddle>
          <Stack spacing={4}>
            <Box>
              <Heading size="md" fontWeight="bold">
                Meta Title
              </Heading>
              <Text fontSize="sm">{task.contentItem.metaTitle}</Text>
            </Box>
            <Box>
              <Heading size="md" fontWeight="bold">
                Meta Description
              </Heading>
              <Text fontSize="sm">{task.contentItem.metaDescription}</Text>
            </Box>
          </Stack>
        </PanelMiddle>
        <PanelMiddle>
          <PanelTop>
            <Flex justifyContent="space-between" alignItems="center">
              <Box>
                <Heading size="md">Thumbnail</Heading>
                <Text fontSize="sm" color={AppColors.textGray}>
                  Recommended Size: 600px x 400px
                </Text>
              </Box>
              <SecondaryButton onClick={onThumbnailUpload}>Upload</SecondaryButton>
            </Flex>
          </PanelTop>
          <PanelBottom>
            {file && (
              <Box display="inline-flex" position="relative">
                <Image
                  htmlHeight={400}
                  htmlWidth={600}
                  objectFit="cover"
                  src={file.url}
                  alt={file.name}
                  crossOrigin=""
                />
                <PrimaryIconButton
                  position="absolute"
                  top="-7px"
                  right="-15px"
                  justifyContent="flex-end"
                  paddingLeft={0}
                  paddingRight={0}
                  marginLeft={0}
                  marginRight={0}
                  height="auto"
                  backgroundColor="white"
                  outline="none"
                  minWidth="auto"
                  borderRadius="50%"
                  icon={faTimesCircle}
                  actionName="Remove Image"
                  onClick={onThumbnailRemove}
                  zIndex={1}
                />
              </Box>
            )}
            {!file && (
              <Text>
                <AppIcon icon={faInfoCircle} standardRightMargin />
                Not provided.
              </Text>
            )}
          </PanelBottom>
        </PanelMiddle>
        <PanelBottom textAlign="right" showTopBorder>
          {isCompleted && (
            <Text color={AppColors.green}>
              <AppIcon icon={faCheckCircle} standardRightMargin />
              Already Reviewed
            </Text>
          )}
          {!isCompleted && (
            <>
              {!isContentModified && !showReject && (
                <PrimaryButton mr={2} onClick={onSubmit} isLoading={isSubmitting}>
                  Approve
                </PrimaryButton>
              )}
              {isContentModified && (
                <Stack spacing={2}>
                  <AlertInfo>
                    <Text>
                      <strong>NOTE:</strong> You have made changes to the content.
                    </Text>
                  </AlertInfo>
                  <Stack isInline spacing={2} justifyContent="flex-end">
                    <PrimaryButton onClick={onSubmit} isLoading={isSubmitting}>
                      Save Changes and Approve
                    </PrimaryButton>
                    <CancelButton onClick={handleCancelModifications}>Revert Changes</CancelButton>
                  </Stack>
                </Stack>
              )}
              {!isContentModified && !showReject && <DangerButton onClick={onReject}>Revise</DangerButton>}
              {showReject && (
                <Box>
                  <Box>
                    <Textarea
                      onChange={onChange}
                      value={rejectionComments}
                      resize="none"
                      placeholder="Revision(s) you would like made to the content"
                      autoFocus
                    />
                  </Box>
                  <Box mt={2} textAlign="right">
                    <Stack isInline spacing={2} justifyContent="flex-end">
                      <PrimaryButton onClick={onSubmit} isLoading={isSubmitting}>
                        Submit
                      </PrimaryButton>
                      <CancelButton onClick={onCancel}>Cancel</CancelButton>
                    </Stack>
                  </Box>
                </Box>
              )}
            </>
          )}
        </PanelBottom>
      </PanelGroup>
    </>
  );
}
