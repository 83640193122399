import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

import {
  RejectedKeyword,
  useKeywordResearchReviewTaskWorkspaceQuery,
  useSubmitKeywordReviewMutation,
} from "../../../../generated/graphql";

interface Options {
  taskId: number;
}

export function useKeywordResearchReview(options: Options) {
  const { taskId } = options;
  const toast = useToast();
  const history = useNavigate();

  const [rejectedKeywords, setRejectedKeywords] = useState<RejectedKeyword[]>([]);

  const { data, loading, error } = useKeywordResearchReviewTaskWorkspaceQuery({
    variables: {
      taskId,
    },
    fetchPolicy: "network-only",
  });

  const isCompleted = data?.task?.status === "completed";
  const keywordReviewId = data?.task?.keywordReview?.id;
  const primaryProposedKeywords = data?.task?.keywordReview?.primaryProposedKeywords ?? [];
  const haloProposedKeywords = data?.task?.keywordReview?.haloProposedKeywords ?? [];
  const summary = data?.task?.keywordReview?.summary ?? "";

  const [submitReview, { loading: submitLoading }] = useSubmitKeywordReviewMutation();

  async function onSubmit() {
    const response = await submitReview({
      variables: {
        input: {
          taskId,
          customerKeywordProposalId: data?.task?.keywordReview?.id ?? 0,
          rejectedKeywords,
        },
      },
    });

    if (response?.data?.submitKeywordReview.done) {
      toast({
        title: "Completed",
        description: "The Keyword Review was submitted successfully.",
        status: "success",
      });
    }

    history("/");
  }

  function onRejected(proposedCustomerKeywordId: number, reason: string) {
    setRejectedKeywords([
      ...rejectedKeywords.filter((x) => x.proposedCustomerKeywordId !== proposedCustomerKeywordId),
      { proposedCustomerKeywordId, reason },
    ]);
  }

  function onUndo(proposedCustomerKeywordId: number) {
    setRejectedKeywords(rejectedKeywords.filter((x) => x.proposedCustomerKeywordId !== proposedCustomerKeywordId));
  }

  return {
    loading,
    error,
    isCompleted,
    keywordReviewId,
    primaryProposedKeywords,
    haloProposedKeywords,
    rejectedKeywords,
    summary,
    submitLoading,
    onSubmit,
    onRejected,
    onUndo,
  };
}
