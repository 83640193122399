import { Box, Text, Button } from "@chakra-ui/react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import AppIcon from "../../components/app-icon";
import AppLink from "../../components/app-link";

export function NotFoundRoute() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems={{ xs: "flex-start", lg: "center" }}
      height="100vh"
      paddingTop={4}
    >
      <Box p={4} mt={1} width={350} shadow="md" borderWidth="1px" borderRadius={4}>
        <Box marginBottom={2}>
          <Text as="h4" fontSize="3xl">
            Oops...
          </Text>
        </Box>
        <Box display="flex" justifyContent="stretch" alignItems="space-between" marginBottom={2}>
          <Box width="20%">
            <AppIcon icon={faExclamationTriangle} size="2x" />
          </Box>
          <Box width="70%">
            <Text fontSize="md">
              Looks like you might be lost. You have tried to reach a page that doesn&apos;t exist.
            </Text>
          </Box>
        </Box>
        <Box>
          <AppLink to="/">
            <Button>Back to App</Button>
          </AppLink>
        </Box>
      </Box>
    </Box>
  );
}
