import { ButtonProps, Button } from "@chakra-ui/react";
import Color from "color";

import { AppColors } from "../../core/custom-theme";

export function PrimaryLinkButton(props: ButtonProps) {
  return (
    <Button
      variant="link"
      color={AppColors.primary.main}
      _hover={{
        color: Color(AppColors.primary.main).darken(0.2).hex(),
      }}
      _active={{
        color: Color(AppColors.primary.main).darken(0.3).hex(),
      }}
      fontSize="14px"
      {...props}
    />
  );
}
