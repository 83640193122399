import { useMemo } from "react";
import {
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  Portal,
  Stack,
  Text,
} from "@chakra-ui/react";
import { faCaretDown, faColumns } from "@fortawesome/free-solid-svg-icons";
import { IconDefinition, IconProp } from "@fortawesome/fontawesome-svg-core";

import AppIcon from "../../components/app-icon";

interface Props {
  items: {
    icon?: IconProp;
    iconColor?: string;
    displayText: string;
    value: string;
  }[];
  value: string[];
  onChange: (value: string[]) => void;
  pluralizedItemName?: string;
  emptyValueTitle?: string;
  selectorIcon?: IconDefinition;
  showSelectAll?: boolean;
}

export function CustomMultiSelect(props: Props) {
  const { items, onChange, value, pluralizedItemName, emptyValueTitle, selectorIcon, showSelectAll = false } = props;

  function handleSelect(selectedValue: string) {
    if (value.includes(selectedValue)) {
      onChange(value.filter((x) => x !== selectedValue));
    } else {
      onChange([...value, selectedValue]);
    }
  }

  function handleSelectAll() {
    onChange(items.map((item) => item.value));
  }

  function handleUnSelectAll() {
    onChange([]);
  }

  const selectedItems = useMemo(() => items.filter((x) => value.includes(x.value)), [items, value]);

  return (
    <Menu closeOnSelect={false} isLazy preventOverflow>
      <MenuButton as={Button}>
        <Stack isInline spacing={2}>
          <AppIcon icon={selectorIcon ? selectorIcon : faColumns} standardRightMargin />
          <Text isTruncated>
            {value.length === 0 && (emptyValueTitle ?? "None")}
            {value.length === 1 && (selectedItems?.[0]?.displayText ?? "")}
            {value.length > 1 && `${selectedItems.length} ${pluralizedItemName ?? "Items"}`}
          </Text>
          <AppIcon icon={faCaretDown} standardLeftMargin />
        </Stack>
      </MenuButton>
      <Portal>
        <MenuList maxH={200} overflowY="scroll">
          {items.length === 0 && <MenuItem isFocusable={false}>No items.</MenuItem>}
          {items.length > 0 && showSelectAll && (
            <>
              <MenuItemOption fontSize="sm" key="select-all" value="select-all" onClick={handleSelectAll}>
                Select All
              </MenuItemOption>
              <MenuItemOption fontSize="sm" key="unselect-all" value="unselect-all" onClick={handleUnSelectAll}>
                Unselect All
              </MenuItemOption>
              <MenuDivider />
            </>
          )}
          {items.map((item, index) => (
            <MenuItemOption
              fontSize="sm"
              key={index}
              value={item.value}
              isChecked={value.includes(item.value)}
              onClick={() => {
                handleSelect(item.value);
              }}
            >
              {item.icon && <AppIcon icon={item.icon} standardRightMargin />}
              {item.displayText}
            </MenuItemOption>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  );
}
