import * as yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { useToast } from "@chakra-ui/react";

import { useSaveCustomerQuestionnaireMutation, useCustomerAccountQuestionnaireQuery } from "../../generated/graphql";
import { handleFormGraphQLError } from "../../util/error-helper";
import { FormHelpers } from "../../util/form-helpers";

interface WebsiteFormValues {
  websiteHosting: string;
  websiteCms: string;
  whoCanChangeWebsite: string;
  contentPreference: string;
}

const websiteSchema = yup.object().shape({
  websiteHosting: yup.string().label("Website Hosting").notRequired().nullable(true),
  websiteCms: yup.string().label("Website CMS").notRequired().nullable(true),
  whoCanChangeWebsite: yup.string().label("Who Can Change Website").required().nullable(false),
  contentPreference: yup.string().label("Content Preference").notRequired().nullable(true),
});

interface ContentFormValues {
  preferredVoice: string;
  targetAudience: string;
  competitorWebsites: string;
  purposeOfContent: string;
  callToActionUrls: string;
  targetKeywords: string;
  contentPublishingPermissions: string;
  isAddImage: string;
}

const contentSchema = yup.object().shape({
  contentPublishingPermissions: yup.string().label("Content Publishing Permissions").required().nullable(false),
  preferredVoice: yup.string().label("Brand Voice").required().nullable(false),
  targetAudience: yup.string().label("Target Audience").required().nullable(false),
  competitorWebsites: yup.string().label("Competitor Websites").notRequired().nullable(true),
  purposeOfContent: yup.string().label("Purpose Of Content").notRequired().nullable(true),
  callToActionUrls: yup.string().label("Call To Action Urls").notRequired().nullable(true),
  targetKeywords: yup.string().label("Target Keywords").notRequired().nullable(true),
  isAddImage: yup.string().label("Is Add Image").notRequired().nullable(true),
});

export function useClientDetailsQuestionnaire() {
  const customerQuestionnaireQueryResult = useCustomerAccountQuestionnaireQuery({
    fetchPolicy: "network-only",
  });
  const [saveCustomerQuestionnaireMutation] = useSaveCustomerQuestionnaireMutation();
  const toast = useToast();

  const customer = customerQuestionnaireQueryResult.data?.customer ?? null;
  const customerQuestionnaire = customerQuestionnaireQueryResult.data?.customer?.customerQuestionnaire ?? null;

  async function onWebsiteSubmit(values: WebsiteFormValues, formikHelpers: FormikHelpers<WebsiteFormValues>) {
    try {
      const response = await saveCustomerQuestionnaireMutation({
        variables: {
          input: {
            websiteCms: FormHelpers.processNullableString(values.websiteCms),
            websiteHosting: FormHelpers.processNullableString(values.websiteHosting),
            whoCanChangeWebsite: FormHelpers.processNullableString(values.whoCanChangeWebsite),
            contentPreference: FormHelpers.processNullableString(values.contentPreference),
          },
        },
      });

      if (response.data?.saveCustomerQuestionnaire.customerQuestionnaire.id) {
        toast({ title: "Website details updated.", description: "Your updates were saved.", status: "success" });
        await customerQuestionnaireQueryResult.refetch();
      }
    } catch (e: any) {
      handleFormGraphQLError(e, "Unable to Save Website Details Updates", toast, formikHelpers.setErrors);
    }
  }

  const websiteFormik = useFormik<WebsiteFormValues>({
    initialValues: {
      websiteCms: customerQuestionnaire?.websiteCms ?? "",
      websiteHosting: customerQuestionnaire?.websiteHosting ?? "",
      whoCanChangeWebsite: customerQuestionnaire?.whoCanChangeWebsite ?? "",
      contentPreference: customerQuestionnaire?.contentPreference ?? "",
    },
    enableReinitialize: true,
    onSubmit: onWebsiteSubmit,
    validateOnMount: true,
    validationSchema: websiteSchema,
  });

  async function onContentSubmit(values: ContentFormValues, formikHelpers: FormikHelpers<ContentFormValues>) {
    try {
      const response = await saveCustomerQuestionnaireMutation({
        variables: {
          input: {
            competitorWebsites: FormHelpers.processNullableString(values.competitorWebsites),
            preferredVoice: FormHelpers.processNullableString(values.preferredVoice),
            targetAudience: FormHelpers.processNullableString(values.targetAudience),
            purposeOfContent: FormHelpers.processNullableString(values.purposeOfContent),
            callToActionUrls: FormHelpers.processNullableString(values.callToActionUrls),
            targetKeywords: FormHelpers.processNullableString(values.targetKeywords),
            contentPublishingPermissions: FormHelpers.processNullableString(values.contentPublishingPermissions),
            isAddImage: values.isAddImage !== "" ? values.isAddImage === "1" : undefined,
          },
        },
      });

      if (response.data?.saveCustomerQuestionnaire.customerQuestionnaire.id) {
        toast({ title: "Content details updated.", description: "Your updates were saved.", status: "success" });
        await customerQuestionnaireQueryResult.refetch();
      }
    } catch (e: any) {
      handleFormGraphQLError(e, "Unable to Save Content Details updates", toast, formikHelpers.setErrors);
    }
  }

  const contentFormik = useFormik<ContentFormValues>({
    initialValues: {
      preferredVoice: customerQuestionnaire?.preferredVoice ?? "",
      targetAudience: customerQuestionnaire?.targetAudience ?? "",
      competitorWebsites: customerQuestionnaire?.competitorWebsites ?? "",
      purposeOfContent: customerQuestionnaire?.purposeOfContent ?? "",
      callToActionUrls: customerQuestionnaire?.callToActionUrls ?? "",
      targetKeywords: customerQuestionnaire?.targetKeywords ?? "",
      contentPublishingPermissions: customerQuestionnaire?.contentPublishingPermissions ?? "",
      isAddImage:
        customerQuestionnaire?.isAddImage !== null ? (customerQuestionnaire?.isAddImage === true ? "1" : "0") : "",
    },
    enableReinitialize: true,
    onSubmit: onContentSubmit,
    validateOnMount: true,
    validationSchema: contentSchema,
  });

  const length = Object.entries(websiteFormik.values).length + Object.entries(contentFormik.values).length;
  const validCount =
    length - (Object.entries(websiteFormik.errors).length + Object.entries(contentFormik.errors).length);

  return {
    isQuestionnaireLoading: customerQuestionnaireQueryResult.loading,
    customer,
    customerQuestionnaire,
    questionnaireLength: length,
    questionnaireValidCount: validCount,
    websiteFormik,
    contentFormik,
    refetch: customerQuestionnaireQueryResult.refetch,
  };
}
