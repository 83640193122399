import React from "react";
import { Navigate } from "react-router";
import { Text, Box, Heading, Stack, Flex, useToast } from "@chakra-ui/react";

import { Loader } from "../../../../components/loader";
import { TOAST_DURATION, TOAST_POSITION } from "../../../../constants/constants";
import { PanelGroup, PanelTop, PanelBottom } from "../../../../components/panels";
import { Table, TableHead, TableRow, TableHeaderCell, TableBody } from "../../../../components/table";
import { PrimaryButton } from "../../../../components/buttons";
import { ProposedKeywordRow } from "./proposed-keyword-row";
import { RichContent } from "../../../../components/rich-content";
import { useKeywordResearchReview } from "./use-keyword-research-review";

interface Props {
  taskId: number;
}

export const KeywordResearchReview = React.memo(function KeywordResearchReview(props: Props) {
  const {
    loading,
    error,
    isCompleted,
    keywordReviewId,
    primaryProposedKeywords,
    haloProposedKeywords,
    rejectedKeywords,
    summary,
    submitLoading,
    onSubmit,
    onRejected,
    onUndo,
  } = useKeywordResearchReview(props);

  const toast = useToast();
  if (loading) {
    return <Loader type="fullView" message="Loading Task" />;
  }

  if (error) {
    toast({
      title: "Error",
      position: TOAST_POSITION,
      description: error.message,
      status: "error",
      duration: TOAST_DURATION,
      isClosable: true,
    });
    return <Navigate to="/" />;
  }

  if (!keywordReviewId) {
    toast({
      title: "Error",
      position: TOAST_POSITION,
      description: "Something went wrong retrieving the task. Please try again.",
      status: "error",
      duration: TOAST_DURATION,
      isClosable: true,
    });
    return <Navigate to="/" />;
  }

  return (
    <Stack spacing={8}>
      <PanelGroup>
        <PanelTop showBottomBorder>
          <Flex justifyContent="space-between">
            <Box>
              <Heading size="md">Primary Keyword Research</Heading>
              <Text fontSize="small">Primary keywords are the target terms that are used when doing work.</Text>
            </Box>
            <Box textAlign="right">
              {!isCompleted && (
                <PrimaryButton onClick={onSubmit} isLoading={submitLoading}>
                  {rejectedKeywords.length === 0 ? "Approve All" : "Submit"}
                </PrimaryButton>
              )}
            </Box>
          </Flex>
        </PanelTop>
        <PanelBottom containsTable>
          <Table isLoading={loading} isEmpty={primaryProposedKeywords.length === 0}>
            <TableHead>
              <TableRow key="header">
                <TableHeaderCell>Keyword</TableHeaderCell>
                <TableHeaderCell>Location</TableHeaderCell>
                <TableHeaderCell>Country</TableHeaderCell>
                <TableHeaderCell>Landing Page URL</TableHeaderCell>
                <TableHeaderCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {primaryProposedKeywords.map((keyword) => (
                <ProposedKeywordRow
                  key={keyword.id}
                  onRejected={onRejected}
                  onUndo={onUndo}
                  proposedKeyword={keyword}
                  rejectDisabled={isCompleted}
                  rejectedKeyword={rejectedKeywords.find((x) => x.proposedCustomerKeywordId === keyword.id) ?? null}
                />
              ))}
            </TableBody>
          </Table>
        </PanelBottom>
      </PanelGroup>
      <PanelGroup>
        <PanelTop showBottomBorder>
          <Heading size="md">Halo Keyword Research</Heading>
          <Text fontSize="small">
            Halo keywords are terms that are related to your primary keywords, but will not be targeted specifically
            when doing work. Halo Keywords will be monitored for rankings.
          </Text>
        </PanelTop>
        <PanelBottom containsTable>
          <Table isLoading={loading} isEmpty={haloProposedKeywords.length === 0}>
            <TableHead>
              <TableRow key="header">
                <TableHeaderCell>Keyword</TableHeaderCell>
                <TableHeaderCell>Location</TableHeaderCell>
                <TableHeaderCell>Country</TableHeaderCell>
                <TableHeaderCell>Landing Page URL</TableHeaderCell>
                <TableHeaderCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {haloProposedKeywords.map((keyword) => (
                <ProposedKeywordRow
                  key={keyword.id}
                  onRejected={onRejected}
                  onUndo={onUndo}
                  proposedKeyword={keyword}
                  rejectDisabled={isCompleted}
                  rejectedKeyword={rejectedKeywords.find((x) => x.proposedCustomerKeywordId === keyword.id) ?? null}
                />
              ))}
            </TableBody>
          </Table>
        </PanelBottom>
      </PanelGroup>
      {summary && (
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Heading size="md">Keyword Summary</Heading>
            <Text fontSize="small">A summary explanation sub text</Text>
          </PanelTop>
          <PanelBottom>
            <RichContent content={summary} />
          </PanelBottom>
        </PanelGroup>
      )}
    </Stack>
  );
});
