import { Box, Image, Text } from "@chakra-ui/react";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";

import AppIcon from "../../app-icon";
import { MAIN_NAV_HEIGHT } from "../../../constants/constants";
import { AppColors } from "../../../core/custom-theme";

interface Props {
  hasSecondaryNav?: boolean;
  partnerName?: string;
  partnerLogoUrl?: string;
}

export function MainNavSmallAnonymous(props: Props) {
  const { hasSecondaryNav, partnerName, partnerLogoUrl } = props;

  return (
    <Box
      height={MAIN_NAV_HEIGHT}
      backgroundColor={AppColors.textNormal}
      position="sticky"
      top="0"
      zIndex={999}
      display="grid"
      alignItems="center"
      gridTemplateColumns="1fr 205px 1fr"
      marginBottom={hasSecondaryNav === true ? 0 : 2}
      boxShadow={hasSecondaryNav === true ? undefined : "2px 0px 12px rgba(0, 0, 0, 0.6)"}
    >
      <Box gridColumn={1} paddingLeft={1}></Box>
      <Box gridColumn={2} textAlign="center">
        {!partnerLogoUrl && (
          <Text marginLeft={2} color={AppColors.white}>
            <AppIcon icon={faChartLine} standardRightMargin />
            {partnerName ?? "Launch Pad"}
          </Text>
        )}
        {partnerLogoUrl && <Image objectFit="contain" src={partnerLogoUrl} maxHeight={50} crossOrigin="" />}
      </Box>
      <Box gridColumn={3} textAlign="right" paddingRight={1}></Box>
    </Box>
  );
}
