import { useContext } from "react";
import { Box, Button, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

import { AuthContext } from "../../../core/auth-manager";
import AppIcon from "../../app-icon";
import AppLink from "../../app-link";
import { AppColors } from "../../../core/custom-theme";

export function MainNavProfile() {
  const authContext = useContext(AuthContext);
  const { viewer, logout } = authContext;

  return (
    <Box paddingRight={2} textAlign="right" fontSize="14px">
      <Box display="flex" justifyContent="flex-end">
        <Box marginRight={2}>
          <span style={{ display: "block" }}>{viewer?.user?.fullName ?? "Not Logged In"}</span>
          <span style={{ display: "block", fontSize: ".75rem" }}>{authContext.customer?.name ?? ""}</span>
        </Box>
        <Menu autoSelect={false}>
          <Button
            variant="ghost"
            as={MenuButton}
            color={AppColors.white}
            backgroundColor={AppColors.textNormal}
            _hover={{ backgroundColor: AppColors.textNormal }}
            _active={{ backgroundColor: AppColors.textNormal }}
          >
            <AppIcon icon={faEllipsisV} />
          </Button>
          <MenuList color="black">
            <AppLink to="/profile" color="black" _hover={{ textDecor: "none" }}>
              <MenuItem color="black">Profile</MenuItem>
            </AppLink>
            <AppLink to="/account" color="black" _hover={{ textDecor: "none" }}>
              <MenuItem color="black">Account Setup</MenuItem>
            </AppLink>
            <MenuItem color="black" onClick={logout}>
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Box>
  );
}
