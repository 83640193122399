import { lazy, Suspense } from "react";
import { Route, Routes as RouterRoutes } from "react-router-dom";
import { DefaultRoute } from "./_default";
import { Signup } from "./signup";
import { LogIn } from "./login";
import { Sso } from "./sso";
import { RequestPasswordReset } from "./request-password-reset";
import { ResetPassword } from "./reset-password";
import { PartnerPackages } from "./partner-packages";
import { Dashboard } from "./dashboard";
import { RouteAuthorization } from "../components/route-authorization";
import { KeywordRankings } from "./keyword-rankings";
import { Account } from "./account";
import { Messages } from "./messages";
import { Conversation } from "./conversation";
import { Task } from "./task";
import { NotFoundRoute } from "./not-found";
import { Deliverable } from "./deliverable";
import { DeliverableFile } from "./deliverable-file";
import { Analytics } from "./analytics";
import { Loader } from "../components/loader";
import { GoogleOAuthProvider } from "@react-oauth/google";

const Billing = lazy(() => import("./billing"));
const CampaignInvoices = lazy(() => import("./campaign-invoices"));
const CampaignInvoiceDetails = lazy(() => import("./campaign-invoice-details"));
// const Invoices = lazy(() => import("./invoices"));
// const InvoiceDetails = lazy(() => import("./invoice-details"));
const WorkSummary = lazy(() => import("./work-summary"));
const ProfileRoute = lazy(() => import("./profile"));
const LocationsReport = lazy(() => import("./locations-report"));
const DeliverableGbp = lazy(() => import("./deliverable-gbp"));
const GoogleCallback = lazy(() => import("./google-callback"));
const LocationReviews = lazy(() => import("./location-reviews"));

export function Routes() {
  return (
    <Suspense fallback={<Loader />}>
      <RouterRoutes>
        <Route path="/" element={<DefaultRoute />} />
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/login"
          element={
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""}>
              <LogIn />
            </GoogleOAuthProvider>
          }
        />
        <Route path="/sso/:code/*" element={<Sso />} />
        <Route path="/request-password-reset" element={<RequestPasswordReset />} />
        <Route path="/reset-password/:id" element={<ResetPassword />} />
        <Route
          path="/packages"
          element={
            <RouteAuthorization requireCustomer>
              <PartnerPackages />
            </RouteAuthorization>
          }
        />
        <Route
          path="/dashboard"
          element={
            <RouteAuthorization requireCustomer>
              <Dashboard />
            </RouteAuthorization>
          }
        />
        <Route
          path="/keyword-rankings"
          element={
            <RouteAuthorization requireCustomer>
              <KeywordRankings />
            </RouteAuthorization>
          }
        />
        <Route
          path="/work-summary"
          element={
            <RouteAuthorization requireCustomer>
              <WorkSummary />
            </RouteAuthorization>
          }
        />
        <Route
          path="/messages"
          element={
            <RouteAuthorization requireCustomer>
              <Messages />
            </RouteAuthorization>
          }
        />
        <Route
          path="/messages/sent"
          element={
            <RouteAuthorization requireCustomer>
              <Messages />
            </RouteAuthorization>
          }
        />
        <Route
          path="/messages/:conversationId"
          element={
            <RouteAuthorization requireCustomer>
              <Conversation />
            </RouteAuthorization>
          }
        />
        <Route
          path="/profile"
          element={
            <RouteAuthorization requireCustomer>
              <ProfileRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/account"
          element={
            <RouteAuthorization requireCustomer>
              <Account />
            </RouteAuthorization>
          }
        />
        <Route
          path="/tasks/:id"
          element={
            <RouteAuthorization requireCustomer>
              <Task />
            </RouteAuthorization>
          }
        />
        <Route path="/deliverable/:publicId" element={<Deliverable />} />
        <Route path="/deliverable-gbp/:publicId" element={<DeliverableGbp />} />
        <Route path="/deliverable-file/:order/:file" element={<DeliverableFile />} />
        <Route
          path="/analytics"
          element={
            <RouteAuthorization requireCustomer>
              <Analytics />
            </RouteAuthorization>
          }
        />
        <Route
          path="/billing"
          element={
            <RouteAuthorization requireCustomer>
              <Billing />
            </RouteAuthorization>
          }
        />
        <Route
          path="/invoices"
          element={
            <RouteAuthorization requireCustomer>
              <CampaignInvoices />
            </RouteAuthorization>
          }
        />
        <Route
          path="/invoices/:invoiceId"
          element={
            <RouteAuthorization requireCustomer>
              <CampaignInvoiceDetails />
            </RouteAuthorization>
          }
        />
        <Route
          path="/locations-report"
          element={
            <RouteAuthorization requireCustomer>
              <LocationsReport />
            </RouteAuthorization>
          }
        />
        <Route
          path="/google-callback"
          element={
            <RouteAuthorization>
              <GoogleCallback />
            </RouteAuthorization>
          }
        />
        <Route
          path="/location-reviews"
          element={
            <RouteAuthorization>
              <LocationReviews />
            </RouteAuthorization>
          }
        />
        <Route path="*" element={<NotFoundRoute />} />
      </RouterRoutes>
    </Suspense>
  );
}
