import { Box, Text, Progress, Flex } from "@chakra-ui/react";

import { PageHeading } from "../../components/page-heading";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { CompanyDetails } from "./company-details";
import { ContentDetails } from "./content-details";
import { WebsiteDetails } from "./website-details";
import { useClientDetailsQuestionnaire } from "./use-client-questionnaire";
import { useClientAccount } from "./use-client-account";
import { ActionItemsBar } from "../../common/action-items-bar";
import { AppColors } from "../../core/custom-theme";

export function Account() {
  const { questionnaireLength, questionnaireValidCount, ...questionnaire } = useClientDetailsQuestionnaire();
  const { customerAccountLength, customerAccountValidCount, ...customerAccount } = useClientAccount();

  const progress =
    ((questionnaireValidCount + customerAccountValidCount) / Math.max(1, questionnaireLength + customerAccountLength)) *
    100;

  return (
    <LayoutBase pageTitle="Account Setup">
      <MainNav />
      <Container centerHorizontally>
        <ActionItemsBar />
        <PageHeading>Account Setup</PageHeading>
        <PanelGroup marginBottom={0}>
          <PanelTop showBottomBorder>
            <Text fontSize="lg" fontWeight="bold">
              {`Let's get to know you better`}
            </Text>
            <Text fontSize="xs" color={AppColors.textDarkGray}>
              Complete these tasks at your own pace to get your account up and running.
            </Text>
          </PanelTop>
        </PanelGroup>
        <PanelBottom position="sticky" zIndex={999} top="64px" marginBottom={4}>
          <Box display="flex" justifyContent="space-between">
            <Text fontSize="sm" color={AppColors.textDarkGray}>
              Progress
            </Text>
            <Text fontSize="sm">{`${progress.toFixed(0)}%`}</Text>
          </Box>
          <Progress color="green" size="lg" value={progress} marginTop={1} />
        </PanelBottom>
        <Flex justifyContent={{ base: "stretch", md: "space-between" }} flexDir={{ base: "column-reverse", md: "row" }}>
          <Box width={{ base: "100%", md: "49%" }}>
            <CompanyDetails
              detailsFormik={customerAccount.detailsFormik}
              isOverviewLoading={customerAccount.isOverviewLoading}
            />
            <WebsiteDetails
              websiteFormik={questionnaire.websiteFormik}
              isQuestionnaireLoading={questionnaire.isQuestionnaireLoading}
            />
          </Box>
          <Box width={{ base: "100%", md: "49%" }}>
            <ContentDetails
              contentFormik={questionnaire.contentFormik}
              isQuestionnaireLoading={questionnaire.isQuestionnaireLoading}
            />
          </Box>
        </Flex>
      </Container>
    </LayoutBase>
  );
}
