import { Navigate, useNavigate } from "react-router";
import { Box, useToast } from "@chakra-ui/react";

import { Loader } from "../../components/loader";
import { useTaskWorkspaceQuery } from "../../generated/graphql";
import { PageHeading } from "../../components/page-heading";
import { TOAST_DURATION, TOAST_POSITION } from "../../constants/constants";
import { TASK_TYPES } from "../../constants/constants";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { KeywordResearchReview } from "./workspaces/keyword-research-review";
import { ContentReview } from "./workspaces/content-review";
import { ContentStrategyReview } from "./workspaces/content-strategy-review";

interface Props {
  taskId: number;
}

function TaskWorkspace(props: Props) {
  const { taskId } = props;
  const toast = useToast();
  const history = useNavigate();
  const { loading, error, data } = useTaskWorkspaceQuery({
    variables: {
      taskId,
    },
  });

  if (loading) {
    return <Loader type="fullView" message="Loading Task" />;
  }

  if (error) {
    toast({
      title: "Error",
      position: TOAST_POSITION,
      description: error.message,
      status: "error",
      duration: TOAST_DURATION,
      isClosable: true,
    });
    return <Navigate to="/" />;
  }

  if (!data || !data.task) {
    toast({
      title: "Error",
      position: TOAST_POSITION,
      description: "Something went wrong retrieving the task. Please try again.",
      status: "error",
      duration: TOAST_DURATION,
      isClosable: true,
    });
    return <Navigate to="/" />;
  }

  function renderWorkspace() {
    switch (data?.task?.taskType.id) {
      case TASK_TYPES.KEYWORD_RESEARCH_CUSTOMER_REVIEW:
        return <KeywordResearchReview {...props} />;
      case TASK_TYPES.CUSTOMER_CONTENT_REVIEW:
        return <ContentReview {...props} />;
      case TASK_TYPES.CONTENT_STRATEGY_CUSTOMER_REVIEW:
        return <ContentStrategyReview {...props} />;
      default:
        toast({
          title: "Error",
          position: TOAST_POSITION,
          description: "Something went wrong retrieving the task. Please try again.",
          status: "error",
          duration: TOAST_DURATION,
          isClosable: true,
        });
        history("/");
        console.error(`Unable to find a workspace for ${data?.task?.taskType.name} task type.`);
        return null;
    }
  }

  return (
    <LayoutBase pageTitle="Task Workspace">
      <MainNav />
      <Container centerHorizontally>
        <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
          <Box>
            <PageHeading>{data.task.taskType.name}</PageHeading>
          </Box>
        </Box>
        <Box>{renderWorkspace()}</Box>
      </Container>
    </LayoutBase>
  );
}

export default TaskWorkspace;
