import React from "react";
import AuthManager from "./auth-manager";
import { useLogoutUserMutation, useAuthManagerQuery } from "../../generated/graphql";

interface Props {
  children: React.ReactNode;
}

export function AuthManagerContainer(props: Props) {
  const [logoutUserMutation] = useLogoutUserMutation();
  const queryResult = useAuthManagerQuery({ fetchPolicy: "network-only" });

  return (
    <AuthManager queryResult={queryResult} logoutUserMutation={logoutUserMutation}>
      {props.children}
    </AuthManager>
  );
}
