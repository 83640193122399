import { Navigate } from "react-router-dom";
import { Box, FormLabel, Input, FormControl, FormErrorMessage, Divider, Button, Text } from "@chakra-ui/react";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

import { PrimaryButton } from "../../components/buttons";
import { AppColors } from "../../core/custom-theme";
import AppIcon from "../../components/app-icon";
import { LayoutBase } from "../../components/layout";
import { useLogin } from "./use-login";
import AppLink from "../../components/app-link";

export function LogIn() {
  const { authContext, formik, canUseGoogle, onGoogleLoginClick, googleClientId } = useLogin();

  if (!googleClientId) {
    throw new Error("Unable to setup Login with Google");
  }

  if (authContext.isLoggedIn && !!authContext.viewer) {
    return <Navigate to="/" />;
  }

  return (
    <LayoutBase pageTitle="Login">
      <Box
        display="flex"
        justifyContent="center"
        alignItems={["flex-start", "center"]}
        height="100vh"
        paddingBottom={8}
      >
        <Box p={4} mt={1} width={[350, 414]} borderRadius={4}>
          {/*
          TODO: Add Logo for launchpad
          <Flex justifyContent="center" marginBottom={0}>
            <Image
              justifyContent="center"
              src={Logo}
              alt="Creatives Scale"
              width="250px"
              height="100px"
              objectFit="contain"
            />
          </Flex>
          */}
          <Box padding={8} marginBottom={2} textAlign="left" bg={AppColors.white} borderRadius={4}>
            <form onSubmit={formik.handleSubmit} noValidate={true}>
              <FormControl isRequired isInvalid={!!formik.errors.email && formik.touched.email}>
                <FormLabel fontSize={14} fontWeight="normal">
                  Email
                </FormLabel>
                <Input type="email" id="email" value={formik.values.email} onChange={formik.handleChange} autoFocus />
                {formik.touched.email && formik.errors.email && (
                  <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isRequired marginTop={4} isInvalid={!!formik.errors.password && formik.touched.password}>
                <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between" alignItems="center">
                  <FormLabel fontSize={14} fontWeight="normal">
                    Password
                  </FormLabel>
                  <AppLink to="/request-password-reset" fontSize="sm">
                    <Text fontSize={12} fontWeight={500} color={AppColors.blue} cursor="pointer">
                      Forgot your password?
                    </Text>
                  </AppLink>
                </Box>
                <Input type="password" id="password" value={formik.values.password} onChange={formik.handleChange} />
                {formik.touched.password && formik.errors.password && (
                  <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
                )}
              </FormControl>
              <Box marginTop={4}>
                <PrimaryButton
                  width="100%"
                  bg={AppColors.textNormal}
                  _hover={{ bg: AppColors.textNormal }}
                  _active={{ bg: AppColors.textNormal }}
                  onClick={formik.submitForm}
                  isDisabled={formik.isSubmitting}
                  isLoading={formik.isSubmitting}
                  loadingText="Logging In..."
                >
                  Login
                </PrimaryButton>
              </Box>
            </form>
            <Box marginTop={4}>
              <Divider mb={4} />
              <Button onClick={onGoogleLoginClick} isDisabled={!canUseGoogle} width="100%" variant="outline">
                <AppIcon icon={faGoogle} wideRightMargin /> Login with Google
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </LayoutBase>
  );
}
