import { SimpleGrid } from "@chakra-ui/react";
import { faBalanceScale, faChartLine, faTrophy } from "@fortawesome/free-solid-svg-icons";

import { MetricBox } from "../../components/metric-box";
import { AppColors } from "../../core/custom-theme";
import NoDataAvailable from "../../components/no-data-available";
import { Dashboard_MetricFragment } from "../../generated/graphql";

interface Props {
  customer: Dashboard_MetricFragment | null;
}

export default function DashboardKeywordMetrics(props: Props) {
  const { customer } = props;
  const { keywordsOnFirstPage, keywordsImproved, averageRanking } = customer ?? {};

  return (
    <SimpleGrid columns={3} gridGap={2}>
      {!customer && <NoDataAvailable />}
      <>
        <MetricBox
          name="Keywords On First Page"
          value={`${keywordsOnFirstPage ?? "No Data Available"}`}
          icon={faTrophy}
          textColor={AppColors.primary.light}
        />
        <MetricBox
          name="Total Keywords Improved"
          value={`${keywordsImproved ?? "No Data Available"}`}
          icon={faChartLine}
          textColor={AppColors.primary.light}
        />
        <MetricBox
          name="Average Ranking for All Keywords"
          value={`${averageRanking?.toFixed(2) ?? "No Data Available"}`}
          icon={faBalanceScale}
          textColor={AppColors.primary.light}
        />
      </>
    </SimpleGrid>
  );
}
