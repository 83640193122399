import { useNavigate } from "react-router-dom";
import { Heading, Text, Divider } from "@chakra-ui/react";

import { PanelBottom, PanelGroup, PanelMiddle, PanelTop } from "../../../components/panels";
import { PrimaryButton } from "../../../components/buttons";

export function Success() {
  const history = useNavigate();

  function onLoginClicked() {
    history("/login");
  }

  return (
    <PanelGroup>
      <PanelTop isHeader>
        <Heading>Success!</Heading>
      </PanelTop>
      <PanelMiddle>
        <Text>{`We've sent an email to you for login`}</Text>
        <Text>Click the link in the email to login and start your journey with us!</Text>
        <Text>
          You can also go to login page and login with google or you can do forgot password and set new password
        </Text>
        <Divider marginTop={12} marginBottom={12} />
        <Text fontSize="sm">{`Can't see the email? Check your spam folder`}</Text>
      </PanelMiddle>
      <PanelBottom>
        <PrimaryButton onClick={onLoginClicked}>Go to Login</PrimaryButton>
      </PanelBottom>
    </PanelGroup>
  );
}
