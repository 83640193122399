import { Loading } from "./loading";
import { SsoError } from "./error";
import { useSso } from "./use-sso";

export function Sso() {
  const { code, showError } = useSso();
  if (!code) {
    return <SsoError />;
  }

  if (showError) {
    return <SsoError />;
  }

  return <Loading />;
}
