import { useMemo, useState } from "react";

import { useCampaignQuery } from "../../generated/graphql";

export function useCampaign() {
  const today = useMemo(() => new Date(), []);
  const [showPackage, setShowPackage] = useState(false);

  const campaignQueryResult = useCampaignQuery({
    fetchPolicy: "network-only",
    variables: {
      endsAt: {
        gte: today,
      },
    },
  });

  function onPackageHelpClick() {
    setShowPackage(true);
  }

  function onPackageHelpClose() {
    setShowPackage(false);
  }

  return {
    loading: campaignQueryResult.loading,
    customerCampaigns: campaignQueryResult.data?.customer?.customerCampaigns.nodes ?? [],
    showPackage,
    onPackageHelpClick,
    onPackageHelpClose,
  };
}
