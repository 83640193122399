import { useContext } from "react";
import { BoxProps, Box, Collapse } from "@chakra-ui/react";

import { PanelContext } from "./context";

interface Props extends BoxProps {
  containsTable?: boolean;
}

export function PanelMiddle(props: Props) {
  const { containsTable, ...rest } = props;
  const context = useContext(PanelContext);
  const borderStyle = "1px solid #E3E8EF";

  if (context.isCollapsible) {
    return (
      <Collapse in={!context.isCollapsed}>
        <Box
          backgroundColor="white"
          borderLeft={borderStyle}
          borderRight={borderStyle}
          padding={containsTable ? 0 : 4}
          {...rest}
        />
      </Collapse>
    );
  }

  return (
    <Box
      backgroundColor="white"
      borderLeft={borderStyle}
      borderRight={borderStyle}
      padding={containsTable ? 0 : 4}
      {...rest}
    />
  );
}
