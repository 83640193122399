import {
  Box,
  Text,
  Input,
  Select,
  FormControl,
  FormLabel,
  FormHelperText,
  Stack,
  Heading,
  FormErrorMessage,
  Flex,
} from "@chakra-ui/react";

import { PrimaryButton, CancelButton } from "../../components/buttons";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { useClientDetailsQuestionnaire } from "./use-client-questionnaire";
import { Loader } from "../../components/loader";
import { QuestionnaireStatusIndicator } from "../../common/questionnaire-status-indicator/questionnaire-status-indicator";
import { AppColors } from "../../core/custom-theme";

const cmsOptions = [
  {
    value: "I do not use a CMS",
    name: "I do not use a CMS",
  },
  {
    value: "Wordpress",
    name: "Wordpress",
  },
  {
    value: "Wix",
    name: "Wix",
  },
  {
    value: "Shopify",
    name: "Shopify",
  },
  {
    value: "WooCommerce",
    name: "WooCommerce",
  },
  {
    value: "Squarespace",
    name: "Squarespace",
  },
  {
    value: "Magento",
    name: "Magento",
  },
  {
    value: "Drupal",
    name: "Drupal",
  },
  {
    value: "Joomla",
    name: "Joomla",
  },
  {
    value: "Other",
    name: "Other",
  },
];

const contentPreferenceOptions = [
  {
    value: "New Content",
    name: "New Content",
  },
  {
    value: "Based on Current Content",
    name: "Based on Current Content",
  },
  {
    value: "The choice is yours",
    name: "The choice is yours",
  },
];

const changeWebsiteOptions = [
  {
    value: "Myself",
    name: "Myself",
  },
  {
    value: "Website Admin",
    name: "Website Admin",
  },
  {
    value: "A friend",
    name: "A friend",
  },
  {
    value: "Other",
    name: "Other",
  },
];

type Props = Pick<ReturnType<typeof useClientDetailsQuestionnaire>, "websiteFormik" | "isQuestionnaireLoading">;

export function WebsiteDetails({ websiteFormik, isQuestionnaireLoading }: Props) {
  function onCancel() {
    websiteFormik.resetForm();
    setTimeout(async () => await websiteFormik.validateForm(), 0);
  }

  return (
    <PanelGroup isCollapsible>
      <PanelTop showBottomBorder>
        <Flex justifyContent="space-between">
          <Box>
            <Heading size="md">Website Details</Heading>
            <Text fontSize="sm" color={AppColors.textDarkGray}>
              Takes about 5 minutes
            </Text>
          </Box>
          <Box>
            <QuestionnaireStatusIndicator values={websiteFormik.values} isValid={websiteFormik.isValid} />
          </Box>
        </Flex>
      </PanelTop>
      <PanelBottom>
        {isQuestionnaireLoading && <Loader type="content" />}
        {!isQuestionnaireLoading && (
          <Stack spacing={4}>
            <FormControl isRequired isInvalid={!!websiteFormik.errors.whoCanChangeWebsite}>
              <FormLabel>Who makes changes to your website?</FormLabel>
              <Select
                id="whoCanChangeWebsite"
                value={websiteFormik.values.whoCanChangeWebsite}
                onChange={websiteFormik.handleChange}
                placeholder="Not provided"
                variant="flushed"
              >
                {changeWebsiteOptions.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{websiteFormik.errors.whoCanChangeWebsite}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!websiteFormik.errors.websiteHosting}>
              <FormLabel>What web hosting service do you use?</FormLabel>
              <Input
                id="websiteHosting"
                value={websiteFormik.values.websiteHosting}
                onChange={websiteFormik.handleChange}
                placeholder="Not provided"
                variant="flushed"
              />
              <FormErrorMessage>{websiteFormik.errors.websiteHosting}</FormErrorMessage>
              <FormHelperText>(Google Domains, GoDaddy, etc.)</FormHelperText>
            </FormControl>
            <FormControl isInvalid={!!websiteFormik.errors.websiteCms}>
              <FormLabel>Do you utilize a Content Management System (CMS) to host and manage your website?</FormLabel>
              <Select
                id="websiteCms"
                value={websiteFormik.values.websiteCms}
                onChange={websiteFormik.handleChange}
                placeholder="Not provided"
                variant="flushed"
              >
                {cmsOptions.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{websiteFormik.errors.websiteCms}</FormErrorMessage>
              <FormHelperText>(Choose the one that best applies)</FormHelperText>
            </FormControl>
            <FormControl isInvalid={!!websiteFormik.errors.contentPreference}>
              <FormLabel>
                For website content, do you want content to be all new and fresh or do you want us to base new content
                on existing web pages?
              </FormLabel>
              <Select
                id="contentPreference"
                value={websiteFormik.values.contentPreference}
                onChange={websiteFormik.handleChange}
                placeholder="Not provided"
                variant="flushed"
              >
                {contentPreferenceOptions.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{websiteFormik.errors.contentPreference}</FormErrorMessage>
              <FormHelperText>(Choose the one that best applies)</FormHelperText>
            </FormControl>
            {websiteFormik.dirty && (
              <Box>
                <PrimaryButton onClick={websiteFormik.submitForm} marginRight="8px">
                  Save
                </PrimaryButton>
                <CancelButton onClick={onCancel}>Cancel</CancelButton>
              </Box>
            )}
          </Stack>
        )}
      </PanelBottom>
    </PanelGroup>
  );
}
