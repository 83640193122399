import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { FormikHelpers, useFormik } from "formik";
import * as yup from "yup";
import { useGoogleLogin, TokenResponse } from "@react-oauth/google";

import { AuthContext } from "../../core/auth-manager";
import { useLoginUserMutation } from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";

interface FormValues {
  email: string;
  password: string;
}

const emailSchema = yup.string().label("Email").required().email().max(255);

const loginSchema = yup.object().shape({
  email: emailSchema,
  password: yup.string().label("Password").required(),
});

export function useLogin() {
  const [loginUserMutation] = useLoginUserMutation();
  const authContext = useContext(AuthContext);
  const toast = useToast();
  const history = useNavigate();
  const [canUseGoogle, setCanUseGoogle] = useState(true);
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function onSubmit(values: FormValues, _formikHelpers: FormikHelpers<FormValues>) {
    try {
      const response = await loginUserMutation({
        variables: {
          input: {
            email: values.email,
            password: values.password,
          },
        },
      });
      if (response.data?.loginUser.isLoggedIn === true) {
        await authContext.refresh();
        history("/");
      } else {
        throw new Error("There was a problem logging in. Please try again.");
      }
    } catch (e: any) {
      toast({ title: "Login", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      email: "",
      password: "",
    },
    validateOnBlur: true,
    validationSchema: loginSchema,
    onSubmit,
  });

  async function onGoogleLoginSuccess(googleResponse: TokenResponse) {
    if (!googleResponse.access_token) {
      toast({ title: "Login", description: "There was a problem logging in. Please try again.", status: "error" });
      return null;
    }

    try {
      const response = await loginUserMutation({
        variables: {
          input: {
            googleAccessToken: googleResponse.access_token,
          },
        },
      });
      if (response.data?.loginUser.isLoggedIn === true) {
        await authContext.refresh();
        history("/");
      } else {
        throw new Error("There was a problem logging in. Please try again.");
      }
    } catch (e: any) {
      toast({ title: "Login", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  async function onGoogleLoginFailure(err: any) {
    if (err?.error !== "popup_closed_by_user") {
      setCanUseGoogle(false);
      if (!err?.details.startsWith("Cookies are not enabled")) {
        toast({
          title: "Unable to login using Google",
          description: err.details ?? "Please try again.",
          status: "warning",
        });
      }
    }
  }

  const login = useGoogleLogin({
    onSuccess: onGoogleLoginSuccess,
    onError: onGoogleLoginFailure,
  });

  function onGoogleLoginClick() {
    login();
  }

  return {
    authContext,
    loginUserMutation,
    googleClientId,
    formik,
    canUseGoogle,
    onGoogleLoginClick,
  };
}
