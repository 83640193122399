import {
  Box,
  Text,
  FormControl,
  FormLabel,
  FormHelperText,
  Select,
  Stack,
  Heading,
  Input,
  FormErrorMessage,
  Flex,
} from "@chakra-ui/react";
import { Textarea } from "@chakra-ui/react";

import { PrimaryButton, CancelButton } from "../../components/buttons";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { Loader } from "../../components/loader";
import { useClientDetailsQuestionnaire } from "./use-client-questionnaire";
import { QuestionnaireStatusIndicator } from "../../common/questionnaire-status-indicator/questionnaire-status-indicator";
import { AppColors } from "../../core/custom-theme";

const preferredVoiceOptions = [
  {
    value: "Casual Professional",
    name: "Casual Professional",
  },
  {
    value: "Business Professional",
    name: "Business Professional",
  },
  {
    value: "Always write as if I wrote the content myself",
    name: "Always write as if I wrote the content myself",
  },
  {
    value: "You choose what is best for the content",
    name: "You choose what is best for the content",
  },
];

const purposeOfContentOptions = ["Lead Generation", "Brand Awareness", "Website Traffic", "Engagement"];

const contentPermissionOptions = [
  {
    value: "I will publish new content on my site",
    name: "I will publish new content on my site",
  },
  {
    value: "Add all new content to my site as an unpublished draft",
    name: "Add all new content to my site as an unpublished draft",
  },
  {
    value: "Publish all new content for me",
    name: "Publish all new content for me",
  },
];

type Props = Pick<ReturnType<typeof useClientDetailsQuestionnaire>, "contentFormik" | "isQuestionnaireLoading">;

export function ContentDetails({ contentFormik, isQuestionnaireLoading }: Props) {
  function onCancel() {
    contentFormik.resetForm();
    setTimeout(async () => await contentFormik.validateForm(), 0);
  }

  return (
    <PanelGroup isCollapsible>
      <PanelTop showBottomBorder>
        <Flex justifyContent="space-between">
          <Box>
            <Heading size="md">Content Details</Heading>
            <Text fontSize="sm" color={AppColors.textDarkGray}>
              Takes about 5 minutes
            </Text>
          </Box>
          <Box>
            <QuestionnaireStatusIndicator values={contentFormik.values} isValid={contentFormik.isValid} />
          </Box>
        </Flex>
      </PanelTop>
      <PanelBottom>
        {isQuestionnaireLoading && <Loader type="content" />}
        {!isQuestionnaireLoading && (
          <Stack spacing={4}>
            <FormControl isRequired isInvalid={!!contentFormik.errors.contentPublishingPermissions}>
              <FormLabel>Content Publishing Permissions</FormLabel>
              <Select
                id="contentPublishingPermissions"
                value={contentFormik.values.contentPublishingPermissions}
                onChange={contentFormik.handleChange}
                placeholder="Not provided"
                variant="flushed"
              >
                {contentPermissionOptions.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{contentFormik.errors.contentPublishingPermissions}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!contentFormik.errors.targetAudience}>
              <FormLabel>Target Audience</FormLabel>
              <Input
                id="targetAudience"
                value={contentFormik.values.targetAudience}
                onChange={contentFormik.handleChange}
                variant="flushed"
              />
              <FormErrorMessage>{contentFormik.errors.targetAudience}</FormErrorMessage>
              <FormHelperText>Who is your target audience?</FormHelperText>
            </FormControl>
            <FormControl isRequired isInvalid={!!contentFormik.errors.preferredVoice}>
              <FormLabel>Brand Voice</FormLabel>
              <Select
                id="preferredVoice"
                value={contentFormik.values.preferredVoice}
                onChange={contentFormik.handleChange}
                placeholder="Not provided"
                variant="flushed"
              >
                {preferredVoiceOptions.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{contentFormik.errors.preferredVoice}</FormErrorMessage>
              <FormHelperText>
                Please select your preferred brand voice to be used for blog content published to your site.
              </FormHelperText>
            </FormControl>
            <FormControl isInvalid={!!contentFormik.errors.purposeOfContent}>
              <FormLabel>Purpose of Content</FormLabel>
              <Select
                id="purposeOfContent"
                value={contentFormik.values.purposeOfContent}
                onChange={contentFormik.handleChange}
                variant="flushed"
                placeholder="Not provided"
              >
                {purposeOfContentOptions.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{contentFormik.errors.purposeOfContent}</FormErrorMessage>
              <FormHelperText>What is your primary business goal at this time?</FormHelperText>
            </FormControl>
            <FormControl isInvalid={!!contentFormik.errors.isAddImage}>
              <FormLabel>Would you like a featured image provided with onsite content?</FormLabel>s
              <Select
                id="isAddImage"
                value={contentFormik.values.isAddImage}
                onChange={contentFormik.handleChange}
                placeholder="Not provided"
                variant="flushed"
              >
                <option value="1" key="true">
                  Yes
                </option>
                <option value="0" key="false">
                  No
                </option>
              </Select>
              <FormErrorMessage>{contentFormik.errors.isAddImage}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!contentFormik.errors.callToActionUrls}>
              <FormLabel>Link To...</FormLabel>
              <Textarea
                id="callToActionUrls"
                value={contentFormik.values.callToActionUrls}
                onChange={contentFormik.handleChange}
                resize="none"
                placeholder="Not provided"
                variant="flushed"
              />
              <FormErrorMessage>{contentFormik.errors.callToActionUrls}</FormErrorMessage>
              <FormHelperText>
                Do you have a specific link or set of links you want us to use as a call to action (i.e. landing page,
                sign up page, or Calendly url)
              </FormHelperText>
            </FormControl>
            <FormControl isInvalid={!!contentFormik.errors.competitorWebsites}>
              <FormLabel>List 3-5 competitor company websites</FormLabel>
              <Textarea
                id="competitorWebsites"
                value={contentFormik.values.competitorWebsites}
                onChange={contentFormik.handleChange}
                resize="none"
                placeholder="Not provided"
                variant="flushed"
              />
              <FormErrorMessage>{contentFormik.errors.competitorWebsites}</FormErrorMessage>
              <FormHelperText>
                We utilize this information to ensure we never link to or from your competitor for any reason.
              </FormHelperText>
            </FormControl>
            <FormControl isInvalid={!!contentFormik.errors.targetKeywords}>
              <FormLabel>
                What keywords or phrases would you or your customers use to find your business online?
              </FormLabel>
              <Textarea
                id="targetKeywords"
                value={contentFormik.values.targetKeywords}
                onChange={contentFormik.handleChange}
                resize="none"
                placeholder="If you were to search for your company products or services today, what key search terms would you use..."
                variant="flushed"
              />
              <FormErrorMessage>{contentFormik.errors.targetKeywords}</FormErrorMessage>
              <FormHelperText>
                If you were to search for your company products or services today, what key search terms would you
                use...
              </FormHelperText>
            </FormControl>
            {contentFormik.dirty && (
              <Box>
                <PrimaryButton onClick={contentFormik.submitForm} marginRight="8px">
                  Save
                </PrimaryButton>
                <CancelButton onClick={onCancel}>Cancel</CancelButton>
              </Box>
            )}
          </Stack>
        )}
      </PanelBottom>
    </PanelGroup>
  );
}
