import { useEffect, useState } from "react";

import { RejectedKeyword, Ui_ProposedKeywordFragment } from "../../../../generated/graphql";

interface Options {
  proposedKeyword: Ui_ProposedKeywordFragment;
  onRejected: (proposedCustomerKeywordId: number, reason: string) => void;
  rejectedKeyword: RejectedKeyword | null;
}

export function useProposedKeywordRow(options: Options) {
  const { proposedKeyword, rejectedKeyword } = options;
  const [showRejected, setShowRejected] = useState(false);
  const [reason, setReason] = useState("");

  useEffect(() => {
    setReason(rejectedKeyword?.reason ?? "");
  }, [rejectedKeyword]);

  function onReject() {
    setShowRejected(true);
  }

  function onSave() {
    options.onRejected(proposedKeyword.id, reason);
    setShowRejected(false);
  }

  function onCancel() {
    setReason(rejectedKeyword?.reason ?? "");
    setShowRejected(false);
  }

  function onReasonChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setReason(event.target.value);
  }

  const isRejected = reason !== "";

  return {
    showRejected,
    reason,
    onReject,
    onSave,
    onCancel,
    onReasonChange,
    isRejected,
  };
}
