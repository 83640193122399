import { useContext, useState } from "react";

import { AuthContext } from "../../core/auth-manager";
import { useConversationsQuery } from "../../generated/graphql";

export function useMessages() {
  const [newMessage, setNewMessage] = useState(false);
  const authContext = useContext(AuthContext);
  const isAdmin = authContext.viewer?.isAdmin ?? false;

  const queryResult = useConversationsQuery({
    fetchPolicy: "network-only",
    variables: {
      limit: 100,
      offset: 0,
    },
  });

  const conversations = queryResult.data?.conversations.nodes ?? [];

  function onNewMessage() {
    setNewMessage(true);
  }

  function onClose() {
    setNewMessage(false);
  }

  return {
    loading: queryResult.loading,
    newMessage,
    conversations,
    onNewMessage,
    onClose,
    isAdmin,
  };
}
