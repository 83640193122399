import { Navigate } from "react-router-dom";

import { ActionItemsBar } from "../../common/action-items-bar";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { PageHeading } from "../../components/page-heading";
import { PanelBox } from "../../components/panels";
import { Loader } from "../../components/loader";
import { LOADER_TYPE } from "../../constants/constants";
import { useAnalytics } from "./use-analytics";

export function Analytics() {
  const { loading, customerId, src } = useAnalytics();

  if (!loading && customerId && !src) {
    return <Navigate to="/" />;
  }

  return (
    <LayoutBase pageTitle="Account Setup">
      <MainNav />
      <Container centerHorizontally height={loading ? "100%" : "85vh"}>
        <ActionItemsBar />
        <PageHeading>Analytics</PageHeading>
        <PanelBox height="100%">
          {loading ? (
            <Loader type={LOADER_TYPE.table} />
          ) : (
            <iframe title="Google Data Studio" src={src} allowFullScreen loading="eager" width="100%" height="100%" />
          )}
        </PanelBox>
      </Container>
    </LayoutBase>
  );
}
