import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import axios from "axios";

import { useUploadFileDialog_GenerateUrlForFileUploadMutation } from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";

export interface UploadFile {
  url: string;
  name: string;
}

interface Options {
  onFileUploaded: (fileId: string, file?: UploadFile) => void;
  onCancel: () => void;
}

export function useUploadFileModal(options: Options) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [generateUrlForFileUpload] = useUploadFileDialog_GenerateUrlForFileUploadMutation();
  const toast = useToast();

  async function onUpload() {
    if (!selectedFile) {
      toast({ title: "Unable to Upload", description: "Please select a file to upload.", status: "error" });
      return;
    }

    let url: string | undefined;
    let fileId: string | undefined;

    setIsUploading(true);

    try {
      const response = await generateUrlForFileUpload({
        variables: {
          input: {
            contentType: selectedFile.type,
            name: selectedFile.name,
            sizeInBytes: selectedFile.size,
          },
        },
      });
      url = response.data?.generateUrlForFileUpload.url;
      fileId = response.data?.generateUrlForFileUpload.fileId;
    } catch (e: any) {
      toast({ title: "Unable to Upload", description: getDisplayMessageForError(e), status: "error" });
      setIsUploading(false);
      return;
    }

    if (!url || !fileId) {
      // No URL or File ID
      toast({ title: "Unable to Upload", description: "Something went wrong. Please try again.", status: "error" });
      setIsUploading(false);
      return;
    }

    try {
      const options = {
        headers: {
          "Content-Type": selectedFile.type,
        },
      };

      await axios.put(url, selectedFile, options);
    } catch (e: any) {
      toast({ title: "Unable to Upload", description: getDisplayMessageForError(e), status: "error" });
      setIsUploading(false);
      return;
    }

    options.onFileUploaded(fileId, { url: URL.createObjectURL(selectedFile), name: selectedFile.name });
    setIsUploading(false);
  }

  function onFileSelected(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0] ?? null;
    if (file) {
      setSelectedFile(file);
    }
  }

  function onCancel() {
    setSelectedFile(null);
    setIsUploading(false);
    options.onCancel();
  }

  return {
    isUploading,
    onCancel,
    onUpload,
    onFileSelected,
    selectedFile,
  };
}
