import { SimpleGrid, Box, Skeleton, Stack } from "@chakra-ui/react";
import { DateTime } from "luxon";

import { useDashboardQuery } from "../../generated/graphql";
import { LayoutBase, MainNav } from "../../components/layout";
import { DashboardMessages } from "./dashboard-messages";
import { DashboardWorkSummary } from "./dashboard-work-summary";
import { ActionItemsBar } from "../../common/action-items-bar";
import { DashboardMeeting } from "./dashboard-meeting";
import DashboardKeywordMetrics from "./dashboard-keyowrd-metics";
import { Container } from "../../components/layout/container";
import { PageHeading } from "../../components/page-heading";
import { SEARCH_ENGINES } from "../../constants/search-engines";

export function Dashboard() {
  const dashboardQueryResult = useDashboardQuery({
    fetchPolicy: "network-only",
    variables: {
      ranksCreatedAt: {
        gte: DateTime.now().minus({ months: 3 }).startOf("day").toJSDate(),
      },
      searchEngine: SEARCH_ENGINES.GOOGLE,
    },
  });

  if (dashboardQueryResult.loading) {
    return (
      <LayoutBase pageTitle="Dashboard">
        <MainNav />
        <Container centerHorizontally>
          <ActionItemsBar />
          <PageHeading>Dashboard</PageHeading>
          <Skeleton height="20px" my="10px" />
          <Skeleton height="20px" my="10px" />
          <Skeleton height="20px" my="10px" />
        </Container>
      </LayoutBase>
    );
  }

  return (
    <LayoutBase pageTitle="Dashboard">
      <MainNav />
      <Container centerHorizontally>
        <ActionItemsBar />
        <PageHeading>Dashboard</PageHeading>
        <Box marginBottom={4}>
          <DashboardKeywordMetrics customer={dashboardQueryResult.data?.customer ?? null} />
        </Box>
        <SimpleGrid minChildWidth="400px" spacing={4}>
          <Box>
            <Stack spacing={4}>
              <DashboardWorkSummary orders={dashboardQueryResult.data?.orders.nodes ?? []} />
              <DashboardMeeting />
            </Stack>
          </Box>
          <Box>
            <Stack spacing={4}>
              <DashboardMessages conversations={dashboardQueryResult.data?.conversations.nodes ?? []} />
            </Stack>
          </Box>
        </SimpleGrid>
      </Container>
    </LayoutBase>
  );
}
